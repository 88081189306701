import { Injectable } from '@angular/core';
import {UserApiService} from '../api/user-api.service';
import {User} from '../model/user';
import {lastValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: User;
  public isAllowViewPrice: boolean;
  
  constructor(
    private userApiService: UserApiService
  ) { }
  
  init() {
    const user = localStorage.getItem('user');
    if (user) {
      this.user = JSON.parse(user);
      this.getUserOrganization();
    }
  }
  
  getUser(): User {
    return this.user;
  }
  
  async getUserOrganization(): Promise<any> {
    if (!this.user.organization) {
      try {
        this.user.organization = await lastValueFrom(this.userApiService.apiGetUserOrganization());
      } catch (e) {
        console.error('get user organization failed', e);
      }
    }

    this.isAllowViewPrice = this.user.organization.isAllowViewPrice || false;
    return this.user.organization;
  }
  
  async getUserOutletList() {
    try {
      const outletList = await lastValueFrom(this.userApiService.apiGetUserOutletList());
      if (outletList && outletList[0]) {
        return outletList;
      }
    } catch (e) {
      console.error('get user address list failed', e);
    }

    return null
  }

  async getMyOrder(next = 0, limit = 10) {
    try {
      return await lastValueFrom(this.userApiService.apiGetMyOrder(next, limit));
    } catch (e) {
      console.error('get my order failed', e);
    }
  }
  
  async getMyOrderCount() {
    try {
      return await lastValueFrom(this.userApiService.apiGetMyOrderCount());
    } catch (e) {
      console.error('get my order count failed', e)
    }
  }
  
  async changePassword(oldPassword: string, newPassword: string) {
    try {
      return await lastValueFrom(this.userApiService.apiUpdatePassword(oldPassword, newPassword));
    } catch (e) {
      console.error('update password failed', e);
    }
  }
  
  async changeProfilePicture(url) {
    try {
      const response = await lastValueFrom(this.userApiService.apiUpdateCurrentUser({profileUrl: url}));
      if (!response) {
        return;
      }
      this.user.profileUrl = url;
      localStorage.setItem('user', JSON.stringify(this.user));
      return response;
    } catch (e) {
      console.error('update profile failed', e);
    }
  }
}
