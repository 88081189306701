import {Component, Input, OnInit} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import {DialogService} from "../../../native/dialog/service/dialog.service";
import {TranslatorService} from "../../../service/translator-service/translator.service";
import {BasketApiService} from "../../../component/shop/basket/api/basket-api.service";
import {BasketItem} from "../../../model/basket/basket";
import {ToastrService} from "ngx-toastr";
import {BasketService} from "../../../component/shop/basket/service/basket.service";
import {UserService} from "../../../native/user/service/user.service";

@Component({
  selector: 'app-recipe-detail-dialog',
  templateUrl: './recipe-detail-dialog.component.html',
  styleUrls: ['./recipe-detail-dialog.component.scss']
})
export class RecipeDetailDialogComponent implements OnInit{
  // init
  @Input() dialog: Dialog;

  public title;
  public description;
  public recipe: any;

  constructor(
      private dialogService:DialogService,
      private translatorService: TranslatorService,
      public basketService: BasketService,
      public userService: UserService
  ){
    this.recipe = {};
  }

  async ngOnInit(): Promise<void> {
    this.recipe = this.dialog.options;
    this.title =  await this.translatorService.
    translate('Recipe Detail');
  }
  onOkay(){
    this.dialogService.close(this.dialog.name, this.dialog.options);
  }

  async onAddToCart(recipeChild): Promise<void>{
    for(const item of this.getItemList(recipeChild)){
      await this.basketService.addItemToBasket(item, 1);
    }
  }

  getItemList(recipeChild): any {
    const itemList = [];
    const checkChildren = (childList) => {
      childList?.forEach(child => {
        if (child.type === 'item') {
          itemList.push(child);
        }
        if (child.childList && child.childList.length > 0) {
          checkChildren(child.childList);
        }
      });
    };
    if(recipeChild.type === 'item'){
      itemList.push(recipeChild);
    }

    checkChildren(recipeChild.childList);

    return itemList;
  }




}
