import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from '../service/login.service';
import {Breadcrumb} from '../../../native/breadcrumb/model/breadcrumb';
import {TranslatorService} from '../../../service/translator-service/translator.service';
import {UserService} from "../../../native/user/service/user.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  public loginCredential: {
    email: string,
    password: string
  };
  public error: any;

  public breadcrumb: Breadcrumb;
  public  isShowPassword;

  constructor(
      private loginService: LoginService,
      private router: Router,
      private translatorService: TranslatorService,
      public userService: UserService
  ) {
    if (this.loginService.isLogin()) {
      if(['hq_operator'].includes(this.userService.user.role.type)){
         this.router.navigate(['/home/recipe']);
      } else{
         this.router.navigate(['/home']);
      }
    }
    this.loginCredential = {
      email: '',
      password: ''
    };
    this.error = {};

    this.initBreadcrumb();
  }

  async initBreadcrumb(): Promise<void> {
    this.breadcrumb = new Breadcrumb({
      title: await this.translatorService.translate('login_breadcrumb_title'),
      breadcrumbList: [
        {
          label: await this.translatorService.translate('login_breadcrumb_link_text')
        }
      ]
    });
  }
  
  async onLogin(): Promise<void> {
    if (this.haveError()) { return; }

    const isLogin = await this.loginService.login(this.loginCredential);
    this.userService.init();
    if (isLogin) {
      if(['hq_operator'].includes(this.userService.user.role.type)){
        await this.router.navigate(['/home/recipe']);
      } else{
        await this.router.navigate(['/home']);
      }
    } else {
      this.error.wrongCredential = true;
    }
  }
  
  haveError(): boolean {
    let haveError = false;
    if (!this.loginCredential.email) {
      haveError = true;
      this.error.email = true;
    }
    
    if (!this.loginCredential.password) {
      haveError = true;
      this.error.password = true;
    }
    this.error.wrongCredential = haveError;
    return haveError;
  }

  onShowPasswordToggle() {
    this.isShowPassword = !this.isShowPassword;
  }

}
