import {Component, Input, OnInit} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import * as _ from 'lodash';
import {RecipeApiService} from "../../../component/recipe/api/recipe-api.service";

@Component({
  selector: 'app-filter-recipe-dialog',
  templateUrl: './filter-recipe-dialog.component.html',
  styleUrls: ['./filter-recipe-dialog.component.scss']
})
export class FilterRecipeDialogComponent implements OnInit {
  // init
  @Input() dialog: Dialog;

  public menuList: any[];
  public menuFocus: boolean;
  public menuSearch: string;
  public query: { menuIdList: string[]};

  constructor(
    private recipeApiService: RecipeApiService,
  ) {
    this.menuList = [];
    this.menuSearch = '';
    this.query = {menuIdList: []};
  }

  ngOnInit(): void {
    this.query =  _.cloneDeep(this.dialog.options.data);
    //this.getMenuList().then();
  }
  // async getMenuList(): Promise<void> {
  //   try {
  //     const {list} = await this.recipeApiService.getMenuList().toPromise();
  //     this.menuList = list;
  //   } catch (e) {
  //     console.error(`fail to get menu list!`, e);
  //   }
  // }

  getSelectedMenuList(): any[] {
    return this.menuList.filter(menu => this.query.menuIdList?.includes(menu._id));
  }

  getUnselectedMenuList(): any[] {
    return this.menuList.filter(menu => !this.query.menuIdList?.includes(menu._id));
  }

  onRemoveMenuFocus(): void {
    setTimeout(() => {
      this.menuFocus = false;
    }, 200);
  }

  onRemoveSelectedMenu(menu): void {
    this.query.menuIdList = this.query.menuIdList.filter(menuId => menu._id !== menuId);
  }

  onSelectMenu(menu): void {
    this.query.menuIdList.push(menu._id);
    this.query.menuIdList = _.uniq(this.query.menuIdList);
    this.menuSearch = '';
    this.menuFocus = false;
  }
  onReset(): void {
    this.query =  { menuIdList: []};
    this.onApply();
  }

  onApply() {
    this.dialog.close(this.query);
  }
}

