<div *ngIf="!loader && product" class="product-box product-style-5 fullHeight">
  <a [routerLink]="['/home/shop/product/', product?._id]">
    <div class="positionRelative">
      <div class="addtocart_btn">
        <a href="javascript:void(0)" (click)="$event.stopPropagation()">
          <div class="qty-box cart_qty open">
            <div class="input-group pe-none">
              <button *ngIf="basketService.itemBasketQtyMap[product._id] > 0 && !this.basketService.isLoadingBasketItemMap[product._id]"
                      (click)="onChangeQty(-1, product)" type="button" class="btn quantity-left-minus pe-auto"
                      data-type="minus" data-field="">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </button>
              <input *ngIf="basketService.itemBasketQtyMap[product._id] > 0 && !this.basketService.isLoadingBasketItemMap[product._id]"
                     type="text" name="quantity"
                     class="form-control input-number h-auto qty-input pe-auto" readonly
                     [(ngModel)]="basketService.itemBasketQtyMap[product._id]">
              <button [disabled]="this.basketService.isLoadingBasketItemMap[product._id]"
                      (click)="onChangeQty(1, product)" type="button" class="btn quantity-right-plus pe-auto"
                      data-type="plus" data-field="">
                <i *ngIf="!this.basketService.isLoadingBasketItemMap[product._id]" class="fa fa-plus" aria-hidden="true"></i>
                <app-loader-overlay *ngIf="this.basketService.isLoadingBasketItemMap[product._id]" class="mx6"></app-loader-overlay>
              </button>
            </div>
          </div>
        </a>
      </div>
      <div class="img-wrapper mt-0">
        <div class="front ratio ratio-1x1 bg-white">
          <img [defaultImage]="'assets/image/img/product/placeholder.jpg'"
               [lazyLoad]="product?.thumbnail"
               [src]="product?.thumbnail"
               [alt]="product.name"
               class="img-fluid blur-up lazyload bg-img object-fit-scale">
        </div>
      </div>
    </div>
    <h4 class="pt-2">{{ product?.name | titlecase }}</h4>
    <div *ngIf="product.supplier && userService.user?.organization?.isAllowViewSupplier" class="fxRow fxColumnCenter pt6">
      <img  *ngIf="product.supplier?.logoUrl"
            [src]="product.supplier?.logoUrl"
            class="h30 w30 img-fluid rounded-circle border">
      <h6 class="colorSuccess" [class.ml12]="product.supplier?.logoUrl">{{ product.supplier?.name | titlecase }}</h6>
    </div>
    <h5 *ngIf="product.pieceInPacket && product.packetInCarton && product.unitOfMeasurement" class="noSelect">{{product.packetInCarton}}x{{product.pieceInPacket}}{{product.unitOfMeasurement}}</h5>
    <div *ngIf="product.isPremium" class="fxRow pb4" style="z-index: 1">
      <div class="py6 px12 backgroundPrimary colorWhite fontBold positionRelative font12 noSelect">
        {{'product_premium_label' | translate}}
        <div class="triangleTop positionAbsolute top0" style="right: -14px" ></div>
        <div class="triangleBottom positionAbsolute bottom0" style="right: -14px" ></div>
      </div>
    </div>
    <h4 *ngIf="userService.isAllowViewPrice">{{ getPrice(product) | currency: productService.Currency.currency:'symbol' }}</h4>
  </a>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
