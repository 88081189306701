import { Injectable } from '@angular/core';
import {BaseService} from "../../../service/base-service/base.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RecipeApiService {

  constructor(private baseService: BaseService) { }

  getRecipeList(next: number, limit: number, query: any = {}): Observable<any>{
    const queryString = this.baseService.buildQuery(query);
    return this.baseService.get(`/v1/user/get/recipe/${next}/${limit}${queryString}`);
  }
  getRecipeById(recipeId: string): Observable<any>{
    return this.baseService.get(`/v1/user/get/recipeById/${recipeId}`);
  }
  createRecipe(body: any): Observable<any>{
    return this.baseService.post(`/v1/user/create/recipe`, body);
  }
  updateRecipe(recipeId: string, body: any): Observable<any>{
    return this.baseService.put(`/v1/user/update/recipe/${recipeId}`, body);
  }
  deleteRecipe(recipeId: string): Observable<any>{
    return this.baseService.delete(`/v1/user/delete/recipe/${recipeId}`);
  }
  refillRecipe(recipeId: string, deliveryInfo?): Observable<any>{
    return this.baseService.post(`/v1/user/refill/recipe/${recipeId}`, deliveryInfo);
  }

  // item measure setting
  getItemMeasureSetting(): Observable<any>{
    return this.baseService.get(`/v1/user/get/itemMeasureSetting`);
  }

  getRecipeCategoryList(): Observable<any>{
    return this.baseService.get(`/v1/user/get/recipe-category/0/10000`);
  }

  getProductListForRecipe(limit: number,next: number ,body: any, selectedCategoryId: string = null): Observable<any> {
    const query = selectedCategoryId ? `?selectedCategoryId=${selectedCategoryId}` : '';
    return this.baseService.post(`/v1/user/get/item/for/recipe/${next}/${limit}${query}`, body);
  }

  getMenuList(next: number, limit: number, query: any = {}): Observable<any>{
    const queryString = this.baseService.buildQuery(query);
    return this.baseService.get(`/v1/user/get/menu/list/${next}/${limit}${queryString}`);
  }

  getProductionSetting(): Observable<any>{
    return this.baseService.get(`/v1/user/get/production-setting`);
  }
}
