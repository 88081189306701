import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/component/home.component';
import { LoginComponent } from './component/login/component/login.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { DialogComponent } from './native/dialog/component/dialog.component';
import {InterceptorService} from './service/interceptor-service/interceptor.service';
import { BreadcrumbComponent } from './native/breadcrumb/component/breadcrumb.component';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from './component/header/component/header.component';
import { FooterComponent } from './component/footer/component/footer.component';
import { SideMenuComponent } from './component/side-menu/component/side-menu.component';
import {ToastrModule} from 'ngx-toastr';
import {AngularSvgIconModule} from 'angular-svg-icon';
import { CollectionComponent } from './component/shop/collection/collection/component/collection.component';
import { ProductBoxComponent } from './component/shop/collection/product-box/component/product-box.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { SkeletonProductBoxComponent } from './component/shop/collection/skeleton-product-box/component/skeleton-product-box.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import { OrderComponent } from './component/order/component/order.component';
import { CheckoutComponent } from './component/shop/checkout/component/checkout.component';
import { BasketComponent } from './component/shop/basket/component/basket.component';
import { ProductDetailComponent } from './component/shop/product-detail/product-detail/component/product-detail.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import { ProductBoxVerticalSliderComponent } from './component/shop/collection/product-box-vertical-slider/component/product-box-vertical-slider.component';
import {DiscountPipe} from './pipes/discount.pipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CheckoutSuccessComponent } from './component/shop/checkout-success/component/checkout-success.component';
import { ProductAvatarComponent } from './component/shop/product-avatar/component/product-avatar.component';
import {AvatarComponent} from "./component/avatar/component/avatar.component";
import { OrderConfirmationComponent } from './component/supplier/order-confirmation/component/order-confirmation.component';
import { ErrorPageComponent } from './component/error-page/component/error-page.component';
import { ConfirmDialogComponent } from './dialog/confirm-dialog/component/confirm-dialog.component';
import { SearchProductComponent } from './component/search-product/component/search-product.component';
import { LoaderOverlayComponent } from './native/loader-overlay/component/loader-overlay.component';
import { PrivacyPolicyComponent } from './component/privacy-policy/component/privacy-policy.component';
import { TermAndConditionComponent } from './component/term-and-condition/component/term-and-condition.component';
import { MyAccountComponent } from './component/my-account/component/my-account.component';
import { ChangePasswordDialogComponent } from './dialog/change-password-dialog/component/change-password-dialog.component';
import { OrderDetailDialogComponent } from './dialog/my-account/order-detail-dialog/component/order-detail-dialog.component';
import { TicketComponent } from './component/ticket/component/ticket.component';
import { AddTicketDialogComponent } from './dialog/add-ticket-dialog/component/add-ticket-dialog.component';
import { TicketDetailComponent } from './component/ticket/ticket-detail/component/ticket-detail.component';
import {RouteReuseStrategy} from '@angular/router';
import {CacheRouteService} from './service/cache-route-service/cache-route.service';
import { KpiExpenseByOutletComponent } from './component/shop/collection/kpi-expense-by-outlet/component/kpi-expense-by-outlet.component';
import { MostOrderedProductComponent } from './component/shop/collection/most-ordered-product/component/most-ordered-product.component';
import { ExpenseListDialogComponent } from './dialog/expense-list-dialog/component/expense-list-dialog.component';
import { AddRecipeDialogComponent } from './dialog/add-recipe-dialog/component/add-recipe-dialog.component';
import { AddMaterialDialogComponent } from './dialog/add-material-dialog/component/add-material-dialog.component';
import { CheckBoxComponent } from './component/check-box/component/check-box.component';
import { RecipeComponent } from './component/recipe/component/recipe.component';
import { InfoDialogComponent } from './dialog/info-dialog/component/info-dialog.component';
import { PickItemDialogComponent } from './dialog/pick-item-dialog/component/pick-item-dialog.component';
import { SelectAddressDialogComponent } from './dialog/select-address-dialog/component/select-address-dialog.component';
import { ProductSelectComponent } from './component/product-select/component/product-select.component';
import { RecipeDetailDialogComponent } from './dialog/recipe-detail-dialog/component/recipe-detail-dialog.component';
import { FilterRecipeDialogComponent } from './dialog/filter-recipe-dialog/component/filter-recipe-dialog.component';
import {SearchPipe} from "./pipes/search.pipe";
import { MenuDetailDialogComponent } from './dialog/menu-detail-dialog/component/menu-detail-dialog.component';

export function HttpLoaderFactory(http: HttpClient): any { return new TranslateHttpLoader(http, 'assets/i18n/app-translate/', '.json'); }

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    DialogComponent,
    BreadcrumbComponent,
    HeaderComponent,
    FooterComponent,
    SideMenuComponent,
    CollectionComponent,
    ProductBoxComponent,
    SkeletonProductBoxComponent,
    OrderComponent,
    CheckoutComponent,
    BasketComponent,
    ProductDetailComponent,
    ProductBoxVerticalSliderComponent,
    DiscountPipe,
    CheckoutSuccessComponent,
    ProductAvatarComponent,
    AvatarComponent,
    OrderConfirmationComponent,
    ErrorPageComponent,
    ConfirmDialogComponent,
    SearchProductComponent,
    LoaderOverlayComponent,
    PrivacyPolicyComponent,
    TermAndConditionComponent,
    MyAccountComponent,
    ChangePasswordDialogComponent,
    OrderDetailDialogComponent,
    TicketComponent,
    AddTicketDialogComponent,
    TicketDetailComponent,
    KpiExpenseByOutletComponent,
    MostOrderedProductComponent,
    ExpenseListDialogComponent,
    AddRecipeDialogComponent,
    AddMaterialDialogComponent,
    CheckBoxComponent,
    RecipeComponent,
    InfoDialogComponent,
    PickItemDialogComponent,
    SelectAddressDialogComponent,
    ProductSelectComponent,
    RecipeDetailDialogComponent,
    FilterRecipeDialogComponent,
    SearchPipe,
    MenuDetailDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
      positionClass: 'toast-bottom-right',
      toastClass: 'ngx-toastr my-2'
    }),
    AngularSvgIconModule.forRoot(),
    InfiniteScrollModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    CarouselModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    {provide: RouteReuseStrategy, useClass: CacheRouteService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
