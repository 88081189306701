import { Injectable } from '@angular/core';
import {BaseService} from "../base-service/base.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MaterialApiService {

  constructor(private baseService: BaseService) { }


  getMaterialList(limit: number,next: number, query: any): Observable<any>{
    const queryString = this.baseService.buildQuery(query);
    return this.baseService.get(`/v1/user/get/materialList/${next}/${limit}${queryString}`);
  }
  createMaterial(body: any): Observable<any>{
    return this.baseService.post(`/v1/user/create/material`, body);
  }
  updateMaterial(materialId: string, body: any): Observable<any>{
    return this.baseService.put(`/v1/user/update/material/${materialId}`, body);
  }
}
