import {Component, Input, OnInit} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import {DialogService} from "../../../native/dialog/service/dialog.service";
import {TranslatorService} from "../../../service/translator-service/translator.service";
import {BasketService} from "../../../component/shop/basket/service/basket.service";
import {UserService} from "../../../native/user/service/user.service";

@Component({
  selector: 'app-menu-detail-dialog',
  templateUrl: './menu-detail-dialog.component.html',
  styleUrls: ['./menu-detail-dialog.component.scss']
})
export class MenuDetailDialogComponent implements OnInit {
  // init
  @Input() dialog: Dialog;

  public title;
  public description;
  public menu: any;

  constructor(
    private dialogService: DialogService,
    private translatorService: TranslatorService,
    public basketService: BasketService,
    public userService: UserService
  ) {
    this.menu = {};
  }

  async ngOnInit(): Promise<void> {
    this.menu = this.dialog.options;
    this.title = await this.translatorService.translate('Recipe Detail');
  }

  onOkay() {
    this.dialogService.close(this.dialog.name, this.dialog.options);
  }

  onGoDetail(recipe: any): void{
    recipe.outlet = this.menu.outlet;
    this.dialogService.open('recipeDetailDialog', recipe);
  }

  getRecipeCostPercentage(recipe): number{
    let recipeMargin = (recipe.totalPrice / recipe.sellPrice) * 100;
    if (!recipe.sellPrice || Number(recipe.sellPrice) === 0){ recipeMargin = 0; }
    return recipeMargin;
  }
  getRecipeProfitPercentage(recipe): number{
    return Math.round(100 - this.getRecipeCostPercentage(recipe));
  }
  getRecipeProfitPrice(recipe): number{
    return (recipe.sellPrice || 0) - recipe.totalPrice;
  }
}
