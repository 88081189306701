// Products
import {CommonObject} from '../common-object/common-object';
import {Supplier} from "../supplier/supplier";

export class Product extends CommonObject {
  _id?: string;
  id?: string;
  defaultPrice: number;
  isAlwaysAvailable: boolean;
  stock: number;
  name: string;
  sku: string;
  supplierSKU: string;
  packing: string;
  supplierIdList: string[];
  supplierList: Supplier[];
  thumbnail: string;
  thumbnails: Thumbnail[];
  categoryIdList: string[];
  categoryList: Category[];
  description: string;
  detail: string;
  discount: number;
  ads: Thumbnail;
  quantity: number;
  basketId: string;
  supplier: Supplier;
  isPremium: boolean;
  unitOfMeasurement: string;
  packetInCarton: number;
  pieceInPacket: number;
}

export interface Thumbnail {
  width: number,
  height: number,
  postion: 0,
  thumbnail: string,
  url: string,
  mediaName: string,
  format: string,
  type: 'image'
}

// export interface Supplier {
//   _id: string,
//   name: string,
//   description: string
// }

export interface Category {
  _id: string,
  label: string,
  iconUrl: string,
  itemCount: number
}


export interface Variants {
  variant_id?: number;
  id?: number;
  sku?: string;
  size?: string;
  color?: string;
  image_id?: number;
}

export interface Images {
  image_id?: number;
  id?: number;
  alt?: string;
  src?: string;
  variant_id?: any[];
}
