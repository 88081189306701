import {EventEmitter, HostListener, Injectable, Output} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {TranslatorService} from "../../../service/translator-service/translator.service";

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  public screenWidth: any;
  public menuToggle: boolean = false;

  public selectedMenu: any;
  public selectedSubMenu: any;

  public isHomeScreen: boolean = false;
  
  @Output() public eMenuSelect: EventEmitter<any>;
  public eMenuSelectSub: any;

  constructor(
    private router: Router,
    public translateService: TranslatorService
  ) {
    this.eMenuSelect = new EventEmitter<any>();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isHomeScreen = false;
      }
    })
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?: any): void {
    this.screenWidth = window.innerWidth;
  }
  
  public sideMenuToggle() {
    this.menuToggle = !this.menuToggle; 
  }

  public onSelectMenu(menu, submenu) {
    this.selectedMenu = menu;
    this.selectedSubMenu = submenu;
    this.eMenuSelect.emit();
  }
}
