import {Component, OnInit} from '@angular/core';
import {Breadcrumb} from '../../../native/breadcrumb/model/breadcrumb';
import {LoginService} from '../../login/service/login.service';
import {UserService} from '../../../native/user/service/user.service';
import {User} from '../../../native/user/model/user';
import {DialogService} from '../../../native/dialog/service/dialog.service';
import {BasketService} from '../../shop/basket/service/basket.service';
import {TranslatorService} from '../../../service/translator-service/translator.service';
import {FileUploader, FileUploaderResponse, FileUploaderResponseType} from '../../../model/file-uploader/file-uploader';
import {ProductService} from '../../../service/product-service/product.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit{
  public user: User;
  public userOutletList: any[];
  public myOrderList: any[];
  public totalOrder: number = 0;
  public totalPendingOrder: number = 0;
  
  public orderNext: any = 0;
  public loadingMoreOrder: Boolean;
  public profileUploader: any;
  
  public openTab: number;
  public breadCrumb: Breadcrumb;
  
  public isLoaded: boolean;
  public isUploading: boolean;
  
  constructor(
    private loginService: LoginService,
    public userService: UserService,
    public productService: ProductService,
    private dialogService: DialogService,
    public basketService: BasketService,
    private translatorService: TranslatorService
  ) {
    this.userOutletList = [];
    this.openTab = 1;
    this.profileUploader = new FileUploader(this, {prefix: 'profile', userId: this.userService.getUser()._id});
  }
  
  async ngOnInit() {
    this.user = this.userService.getUser();
    this.userOutletList = await this.userService.getUserOutletList();
    await this.getMyOrder();
    await this.getMyOrderCount();
    this.isLoaded = true;
    await this.initBreadcrumb();
  }

  // uploader
  async uploadResponse(type: FileUploaderResponseType, response: FileUploaderResponse): Promise<void> {
    switch (type) {
      case 'afterAddFile': {
        this.user.profileUrl = response.url;
        if (this.profileUploader.queueList && this.profileUploader.queueList[0]) {
          this.isUploading = true;
          await this.profileUploader.uploadAll();
        }
        break;
      }
      case 'complete': {
        this.user.profileUrl = response.url;
        if (response.url) {
          await this.userService.changeProfilePicture(response.url);
        }
        this.isUploading = false;
        break;
      }
    }
  }
  
  changeTab(tab) {
    this.openTab = tab;
    const dom = document.getElementById('section-my-account');
    dom.scrollTo({top: 0});
  }
  
  async initBreadcrumb() {
    this.breadCrumb = new Breadcrumb({
      title: await this.translatorService.translate('myAccount_breadcrumb_title'),
      breadcrumbList: [
        {
          label: await this.translatorService.translate('myAccount_breadcrumb_link_dashboard')
        }
      ]
    });
  }

  onChangePassword() {
    this.dialogService.open('changePasswordDialog');
  }
  
  async getMyOrder() {
    const response = await this.userService.getMyOrder(this.orderNext, 5);
    if (response) {
      if (this.orderNext === 0) {
        this.myOrderList = response.list;
      } else {
        this.myOrderList = this.myOrderList.concat(response.list);
      }
      this.orderNext = response.next;
    }
  }
  
  async getMyOrderCount() {
    const response = await this.userService.getMyOrderCount();
    if (response) {
      this.totalOrder = response.totalOrderCount;
      this.totalPendingOrder = response.totalOrderPendingCount
    }
  }
  
  async getMoreMyOrder() {
    this.loadingMoreOrder = true;
    await this.getMyOrder();
    this.loadingMoreOrder = false;
  }

  viewOrderDetail(myOrder) {
    this.dialogService.open('orderDetailDialog', {data: myOrder});
  }
  
  logout() {
    this.loginService.logout();
  }
}
