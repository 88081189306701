import {Component, OnInit} from '@angular/core';
import {SearchProductApiService} from "../api/search-product-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {firstValueFrom} from "rxjs";
import {BasketService} from "../../shop/basket/service/basket.service";
import * as _ from "lodash";
import {SideMenuService} from '../../side-menu/service/side-menu.service';
import {NumberOfItemPerRowService} from "../../../service/number-of-item-per-row-service/number-of-item-per-row.service";

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.scss']
})
export class SearchProductComponent implements  OnInit {

  public searchResultList: any[];
  public loader: boolean;
  public categoryList: any;
  public next;
  public key: string;
  public lastIndexFetchedCategoryId: string;
  public lastIndexFetchedCategoryDescription: string;

  constructor(
      private searchProductApiService: SearchProductApiService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      public basketService: BasketService,
      private sideMenuService: SideMenuService,
      public numberOfItemPerRowService: NumberOfItemPerRowService
  ) {
    this.loader = true;
    this.searchResultList = [];
    this.key = '';
    this.activatedRoute.queryParams.subscribe(async (param) => {
      const routeList = this.router.url.split('/');
      if (routeList[2].includes('search') && _.isEmpty(routeList[3])) {
        this.key = param.key;
        await this.onSearchProduct(this.key);
      }
    });
    if(this.sideMenuService.eMenuSelectSub){ this.sideMenuService.eMenuSelectSub.unsubscribe(); }
    this.sideMenuService.eMenuSelectSub = this.sideMenuService.eMenuSelect.subscribe(() => {
      const selectedCategoryId = this.sideMenuService.selectedSubMenu ? this.sideMenuService.selectedSubMenu._id :
          this.sideMenuService.selectedMenu._id;
      this.onSearchProduct(this.key, selectedCategoryId, true).then();
      const element = document.getElementById('categoryScrollId');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    })
  }

  async ngOnInit(): Promise<void> {

  }

  async onSearchProduct(key: string, selectedCategory: string = null, isClickOnCategory: boolean = false): Promise<void> {
    this.loader = true;
    try {
      const response = await firstValueFrom(this.searchProductApiService.searchProduct(20, 0, {search: key},
          selectedCategory));
      if (!isClickOnCategory) {
        this.categoryList = response.categoryList;
      }
      this.searchResultList = response.list;
      this.next = response.next;
      if (response.list.length > 0) {
        this.lastIndexFetchedCategoryId = response.list[response.list.length - 1]._id;
        this.lastIndexFetchedCategoryDescription = response.list[response.list.length - 1].description;
      }
    } catch (e) {
      console.error('Search product failed!', e);
    }
    this.loader = false;
  }

  async onGetMoreSearchedProduct(key: string): Promise<void> {
    if (!this.next) {
      return;
    }
    try {
      const response = await firstValueFrom(this.searchProductApiService.searchProduct(24, this.next, {search: key}));
      for (const item of response.list) {
        if (this.lastIndexFetchedCategoryId === item._id && this.lastIndexFetchedCategoryDescription === item.description) {
          this.searchResultList[this.searchResultList.length - 1].itemList =
              this.searchResultList[this.searchResultList.length - 1].itemList.concat(item.itemList);
        } else {
          this.searchResultList = this.searchResultList.concat([item])
        }
      }
      this.next = response.next;
      if (response.list.length > 0) {
        this.lastIndexFetchedCategoryId = response.list[response.list.length - 1]._id;
        this.lastIndexFetchedCategoryDescription = response.list[response.list.length - 1].description;
      }
    } catch (e) {
      console.error('Get more search product failed!', e);
    }
  }

  ngOnDestroy() {
    if (this.sideMenuService.eMenuSelectSub){ this.sideMenuService.eMenuSelectSub.unsubscribe();}
  }
}
