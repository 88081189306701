<div class="w400 h100vh background fxColumn p12 sm-w-320">
    <!--title-->
    <div class="fxRow fxRowSpaceBetween">
        <div class="title8">
           <h2>{{'basket_basket_title'| translate}}</h2>
        </div>
        <div class="" (click)="onClose()">
            <svg-icon src="./assets/image/icon/shop/cart/close-icon.svg" class="size16"></svg-icon>
        </div>
    </div>
    <!--divider line-->
    <div class="backgroundDivider h1"></div>
    <!--empty cart-->
    <div *ngIf="!cartItemList[0]" class="title8 colorGray fontMedium fxColumn fxColumnCenter noSelect mt36">
        <h4>{{'basket_empty_basket'| translate}}</h4>
    </div>
    <!--cart item-->
    <div class="fxColumn scrollY">
        <div *ngIf="cartItemList[0]" class="fullHeight scrollY">
            <div *ngFor="let item of cartItemList" class="fxRow fxColumnCenter mt24 positionRelative">
                <div class="w20p">
                   <app-product-avatar [src]="item?.item?.thumbnail" [iconSize]= "50" [style]="{width: '50px', height: '50px'}"></app-product-avatar>
                </div>
                <div class="w30p fxColumn fxRowCenter">
                   <div class="  fxColumn fxRowCenter"> <div class="fontBold ">{{item?.item?.name}}</div></div>
                  <div *ngIf="item?.item?.pieceInPacket && item?.item?.packetInCarton && item?.item?.unitOfMeasurement"  class="colorGray font12">{{item.item.packetInCarton}}x{{item.item.pieceInPacket}}{{item.item.unitOfMeasurement}}</div>
                </div>
                <div class="fxColumn fxColumnCenter fxRowCenter w50p">
                     <div class="qty-box">
                         <div class="input-group">
                              <span>
                                  <button type="button" class="btn" data-type="minus" (click)="basketService.onChangeQty(-1,item)">
                                      <i class="ti-angle-left"></i>
                                  </button>
                              </span>
                              <input type="text" name="quantity" class="form-control h30 w50 bg-transparent"
                                    [(ngModel)]="item.qty" disabled/>
                              <span>
                                  <button type="button" class="btn" data-type="plus" (click)="basketService.onChangeQty(1,item)">
                                      <i class="ti-angle-right"></i>
                                  </button>
                              </span>
                         </div>
                     </div>
                </div>
                <div class="fxRow fxRowCenter fxColumnCenter w10p cursorPointer" (click)="basketService.onRemoveItemInBasket(item)">
                   <svg-icon src="./assets/image/icon/shop/cart/delete-filled-icon.svg" class="size16 colorGray">
                   </svg-icon>
                </div>
                <app-loader-overlay *ngIf="item.isDeleting"></app-loader-overlay>
            </div>
        </div>
         <!--action button-->
         <div *ngIf="cartItemList[0]" class="fxRow fxRowEnd mt36 mr12">
             <button class="btn btn-solid" (click)="onViewCart()">
                 {{'basket_view_basket_button_title'| translate}}
             </button>
         </div>
    </div>
</div>
