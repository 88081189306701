<div class="w500 h350 fxColumn">
  <div class="fxRow fxColumnCenter p12">
    <div class="dot"></div>
    <div class="fontBold font20 px6">{{'Filter Recipe'|translate}}</div>
  </div>
  <div class="fullHeight fxColumn scrollY py6 px24 fadeIn">
    <div class="fxRow fxRowSpaceBetween fxColumnStart py6">
      <div class="w160">{{'Menu'|translate}}:</div>
      <div class="positionRelative fxColumn fxColumnEnd fullWidth">
        <input  class="form-control background" [placeholder]="'Menu'| translate"
                (focus)="menuFocus = true" (blur)="onRemoveMenuFocus()" [(ngModel)]="menuSearch" />
        <div *ngIf="menuFocus" class="background positionAbsolute fullWidth mxh140 scrollY zIndex100 border"
             [style.top.px]="48" style="padding: 0">
          <div *ngFor="let menu of getUnselectedMenuList() | search:menuSearch:['name']"
               class="p12 cursorPointer fxRow fxColumnCenter" (click)="onSelectMenu(menu)">
            <div>{{menu.name}}</div>
          </div>
        </div>

        <div class="fxRow fxWrap noSelect">
          <div *ngFor="let menu of getSelectedMenuList()" class="p6">
            <div class="foreground py6 px12 borderRadius fxRow fxColumnCenter">
              <div>{{menu.name | titlecase}}</div>
              <div class="pl4">
                <svg-icon src="./assets/image/icon/recipe/remove-outline-icon.svg"
                          (click)="onRemoveSelectedMenu(menu)" class="colorDanger cursorPointer size18"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="fxRow fxColumnCenter fxRowEnd p12">
    <button class="btn btn-solid mx6" (click)="onReset()">
      {{'Reset'|translate}}</button>
    <button class="btn btn-solid mx6" (click)="onApply()">
      {{'Apply'|translate}}</button>
  </div>
</div>
