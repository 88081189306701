<div *ngIf="mobileSidebar" class="overlay" (click)="toggleMobileSidebar()"></div>
<div class="scrollY h-no-header positionRelative">
  <app-breadcrumb *ngIf="isLoaded" [showBackButton]="true" [breadcrumb]="breadcrumb"></app-breadcrumb>
  <!-- section start -->
  <section *ngIf="isLoaded" class="fadeIn section-b-space">
    <div class="collection-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-sm-12 col-xs-12">
            <div class="container-fluid p-0">
              <div class="row">
                <div class="col-xl-12">
                  <div class="filter-main-btn mb-2">
                  <span class="filter-btn fxRow fxColumnCenter" (click)="toggleMobileSidebar()">
                    <i class="fa fa-bars font20" aria-hidden="true"></i>
                    <div class="font16 ml6">{{ 'product_sidebar_toggle_text' | translate }}</div>
                  </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div *ngIf="product.thumbnails && product.thumbnails.length > 0 else noImage" class="col-lg-6">
                  <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar  class="product-slick">
                    <ng-container *ngFor="let image of product.thumbnails; index as i">
                      <ng-template carouselSlide [id]="i">
                        <div class="ratio ratio-1x1">
                          <img [defaultImage]="image.thumbnail"
                               [lazyLoad]="image.url"
                               [alt]="product.name" class="img-fluid rounded-2" [src]="image.thumbnail">
                        </div>
                      </ng-template>
                    </ng-container>
                  </owl-carousel-o>
                  <div *ngIf="product.thumbnails.length > 1" class="col-12 p-0">
                    <div class="slider-nav">
                      <owl-carousel-o [options]="ProductDetailsThumbConfig" class="product-slick">
                        <ng-container *ngFor="let image of product.thumbnails; index as i">
                          <ng-template carouselSlide [id]="i">
                            <div class="owl-thumb ratio ratio-1x1" [class.active]="i == activeSlide">
                              <img [defaultImage]="image.thumbnail"
                                   [lazyLoad]="image.url"
                                   [alt]="product.name" class="img-fluid" [src]="image.thumbnail" (click)="owlCar.to(activeSlide = i.toString())">
                            </div>
                          </ng-template>
                        </ng-container>
                      </owl-carousel-o>
                    </div>
                  </div>
                </div>
                <ng-template #noImage>
                  <div class="col-lg-6">
                    <owl-carousel-o [options]="ProductDetailsMainSliderConfig" class="product-slick">
                      <ng-template carouselSlide>
                        <div class="ratio ratio-1x1">
                          <img [defaultImage]="'assets/image/img/product/placeholder.jpg'"
                               [lazyLoad]="'assets/image/img/product/placeholder.jpg'"
                               src="assets/image/img/product/placeholder.jpg"
                               [alt]="product.name" class="img-fluid rounded-2 border">
                        </div>
                      </ng-template>
                    </owl-carousel-o>
                  </div>
                </ng-template>
                <div class="col-lg-6 rtl-text">
                  <div class="product-right">
                    <h2>{{ product.name }}</h2>
                    <div *ngIf="product.supplier && userService.user?.organization?.isAllowViewSupplier"
                         class="fxRow fxColumnCenter fxRowCenter">
                      <div *ngIf="product.supplier?.logoUrl" class="size50">
                        <img [src]="product.supplier?.logoUrl" class="img-fluid rounded-2">
                      </div>
                      <h3 class="colorPrimary" [class.ml12]="product.supplier?.logoUrl">{{ product.supplier?.name }}</h3>
                    </div>
                    <div *ngIf="product.isPremium" class="fxRow pb4" style="z-index: 1">
                      <div class="py6 px12 backgroundPrimary colorWhite fontBold positionRelative font12 noSelect">
                        {{'product_premium_label' | translate}}
                        <div class="triangleTop positionAbsolute top0" style="right: -14px" ></div>
                        <div class="triangleBottom positionAbsolute bottom0" style="right: -14px" ></div>
                      </div>
                    </div>
                    <h6 class="product-title">{{ 'product_product_description' | translate }}</h6>
                    <p>{{ product.description || '...' }}</p>
                    <div class="product-description border-product mt-4">
                      <div class="pt12 justify-content-center justify-content-lg-start row align-items-center g-2">
                        <h3 *ngIf="userService.isAllowViewPrice" class="mb-3 col-auto">
                          {{ getPrice(product) | currency: productService.Currency.currency:'symbol' }}
                        </h3>
                        <h4 *ngIf="product.discount && userService.isAllowViewPrice" class="mb-3 col-auto">
                          <del>{{ getPrice(product) * productService.Currency.price | currency: productService.Currency.currency:'symbol' }}</del>
                          <span class="text-danger">{{ product.discount }}% {{ 'product_product_discount_percentage_off_text' | translate }}</span>
                        </h4>
                      </div>

                      <p *ngIf="product.packetInCarton && product.pieceInPacket && product.unitOfMeasurement">{{ 'product_product_packing_label' | translate }}</p>
                      <h4 *ngIf="product.packetInCarton && product.pieceInPacket && product.unitOfMeasurement" class="noSelect">{{product.packetInCarton}}x{{product.pieceInPacket}}{{product.unitOfMeasurement}}</h4>

                      <h6 class="product-title">{{ 'product_quantity_text' | translate }}</h6>
                      <div class="qty-box">
                        <div class="input-group">
                        <span class="input-group-prepend">
                          <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()" [class.cursorDefault]="counter == 1" [disabled]="counter == 1">
                            <i class="ti-angle-left"></i>
                          </button>
                        </span>
                          <input type="text" name="quantity" class="form-control input-number bg-transparent" [value]="counter" disabled/>
                          <span class="input-group-prepend">
                          <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                            <i class="ti-angle-right"></i>
                          </button>
                        </span>
                        </div>
                      </div>
                    </div>
                    <div class="product-buttons">
                      <a href="javascrip:void(0)" class="btn btn-solid m-0 positionRelative" (click)="addToCart(product)">
                        {{ 'product_add_to_cart_button' | translate }}
                        <app-loader-overlay *ngIf="isAddingItemToCart"></app-loader-overlay>
                      </a>
                    </div>
                    <div *ngIf="product.detail" class="border-product">
                      <h6 class="product-title">{{ 'product_product_shipping_info_label' | translate }}</h6>
                      <p class="text-preline">{{ product.detail }}</p>
                    </div>
                    <div class="border-product"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
              <div class="collection-filter-block border-0">
                <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                <span class="filter-back">
                  <i class="fa fa-angle-left" aria-hidden="true"></i> {{ 'product_sidebar_toggle_back_button' | translate }}
                </span>
                </div>
              </div>
              <app-product-box-vertical-slider 
                [title]="'product_new_product_label' | translate"
                [products]="latestProduct"
                (eProductDetail)="mobileSidebar ? toggleMobileSidebar(): null"
              ></app-product-box-vertical-slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Section ends -->

  <app-footer *ngIf="isLoaded"></app-footer>
  <app-loader-overlay *ngIf="!isLoaded"></app-loader-overlay>
</div>
