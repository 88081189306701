<div class="h-no-header fadeIn scrollY" (scroll)="onScroll($event)">
    <app-breadcrumb [breadcrumb]="breadCrumb"></app-breadcrumb>
    <section class="section-b-space">
        <div class="container">
          <div class="fxRow fxRowSpaceBetween">
            <div class="fxRow fullWidth w250">
              <div class="w50p fxRow fxRowCenter fontBold px12 pt12 border cursorPointer noSelect" (click)="onToggleButton('recipe')" [class.backgroundPrimary]="selectedType === 'recipe'" [class.colorWhite]="selectedType === 'recipe'">{{'Recipe'}}</div>
              <div class="w50p fxRow fxRowCenter fontBold px12 pt12 border cursorPointer noSelect" (click)="onToggleButton('menu')" [class.backgroundPrimary]="selectedType === 'menu'" [class.colorWhite]="selectedType === 'menu'">{{'Menu'}}</div>
            </div>
            <div class="fxRow gap12">
<!--                <div class="borderRadius shadow p12 cursorPointer" [class.backgroundPrimary]="isFilter()" (click)="onOpenFilter()">-->
<!--                  <svg-icon class="size20 colorPrimary" [class.colorWhite]="isFilter()"-->
<!--                            src="./assets/image/icon/recipe/filter-fill-icon.svg"></svg-icon>-->
<!--                </div>-->
              <form class="form_search rounded-1 w450">
                <input id="query search-autocomplete"
                       name="search-product"
                       [placeholder]="(selectedType === 'recipe' ? 'Search any Recipe' : 'Search any Menu')| translate" class="nav-search nav-search-field"
                       [(ngModel)]="query.search"
                       (input)="onSearch()">
                <button type="submit" class="btn-search">
                  <i class="ti-search"></i>
                </button>
              </form>
              <button [class.hidden]="userService.user?.role?.type !== 'hq_operator' || selectedType !== 'recipe'" class="btn btn-solid" (click)="onAddRecipe()">
                {{'Add New Recipe'| translate}}
              </button>
            </div>
          </div>
          <div class="divider my24"></div>
          <div *ngIf="selectedType === 'recipe'">
              <div *ngIf="isLoading && !(recipeList.length > 0)"class="fxRow fxRowCenter fxColumnCenter h200">
                  <app-loader-overlay *ngIf="isLoading"></app-loader-overlay>
              </div>
              <div *ngIf="!isLoading && !(recipeList.length > 0)" class="fxRow fxRowCenter fxColumnCenter h200">
                  <div class="font20 colorGray">{{'No Data'| translate}}</div>
              </div>
              <div *ngIf="recipeList.length > 0" class="table-responsive-xl">
                  <table class="table cart-table verticalAlignCenter">
                      <thead>
                      <tr class="table-head">
                          <th scope="col">
                              {{'Name' | translate}}</th>

                          <th class="textCenter" scope="col">{{'Business Line' | translate}}</th>
                          <th class="textCenter" scope="col">{{'Category' | translate}}</th>
                          <th class="textCenter" scope="col">{{'Last Update' | translate}}</th>
                          <th class="textCenter" scope="col">{{'Cost' | translate}}</th>
                          <th class="textCenter" scope="col">{{'Status' | translate}}</th>
                          <th *ngIf="userService.user?.role?.type === 'hq_operator'" class="textCenter" scope="col">{{'Action' | translate}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let recipe of recipeList" class="cursorPointer backgroundPrimaryHover" (click)="onGoDetail(recipe)">
                          <td class="">
                              {{recipe.name}}
                          </td>
                        <td class="textCenter">
                          <div class="fxColumn fxRowCenter">
                            {{recipe.outlet?.name || '-'}}
                          </div>
                        </td>
                        <td class="textCenter">
                          <div class="fxColumn fxRowCenter">
                            {{recipe.categoryList[0]?.name || '-'}}
                          </div>
                        </td>
                          <td class="textCenter">
                              <div class="fxColumn fxRowCenter">
                                  {{recipe.updatedAt | date:'dd MMM, yyyy hh:mm a'}}
                              </div>
                          </td>
                          <td class="textCenter">
                            <div class="fxColumn fxRowCenter">
                              ${{recipe.totalPrice | number:'1.2-2'}}
                            </div>
                          </td>
                          <td class="textCenter">
                          <div class=" h50 fxRow fxColumnCenter fxRowCenter">
                            <div class="status-container"
                                 [class.status-draft] ="recipe.status === 'draft'"
                                 [class.status-pending] ="recipe.status === 'pending'"
                                 [class.status-accepted] ="recipe.status === 'accepted'"
                                 [class.status-declined] ="recipe.status === 'declined'"
                            >{{recipe.status | uppercase}}</div>
                          </div>
                        </td>
                          <td *ngIf="userService.user?.role?.type === 'hq_operator'" class="textCenter">
                              <div class="fxRow fxColumnCenter fxRowCenter gap12">
                                  <div *ngIf="['pending', 'draft'].includes(recipe.status)" class="cursorPointer" (click)="onEdit($event, recipe)">
                                      <svg-icon src="./assets/image/icon/recipe/edit-icon.svg"
                                                class="size18 colorGray"></svg-icon>
                                  </div>
                                  <div class="cursorPointer" (click)="onDelete($event, recipe)">
                                      <svg-icon src="./assets/image/icon/shop/cart/delete-filled-icon.svg"
                                                class="size20 colorGray"></svg-icon>
                                  </div>
<!--                                    <div *ngIf="recipe.status === 'accepted'" class="cursorPointer" (click)="onRefill($event, recipe)">-->
<!--                                        <svg-icon src="./assets/image/icon/recipe/refill-icon.svg"-->
<!--                                                  class="size18 colorGray"></svg-icon>-->
<!--                                    </div>-->
                              </div>
                          </td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <div *ngIf="isLoading && recipeList.length > 0"class="fxRow fxRowCenter fxColumnCenter h50">
                  <img class="loading-icon" src="./assets/image/img/product/loader.gif" >
              </div>
          </div>
          <div  *ngIf="selectedType === 'menu'">
            <div *ngIf="isLoading && !(menuList.length > 0)"class="fxRow fxRowCenter fxColumnCenter h200">
              <app-loader-overlay *ngIf="isLoading"></app-loader-overlay>
            </div>
            <div *ngIf="!isLoading && !(menuList.length > 0)" class="fxRow fxRowCenter fxColumnCenter h200">
              <div class="font20 colorGray">{{'No Data'| translate}}</div>
            </div>
            <div *ngIf="menuList.length > 0" class="table-responsive-xl">
              <table class="table cart-table verticalAlignCenter">
                <thead>
                <tr class="table-head">
                  <th scope="col">
                    {{'Name' | translate}}</th>
                  <th class="textCenter" scope="col">{{'Create Date' | translate}}</th>
                  <th class="textCenter" scope="col">{{'Business Line' | translate}}</th>
                  <th scope="col">{{'Description' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let menu of menuList" class="cursorPointer backgroundPrimaryHover" (click)="onGoMenuDetail(menu)">
                  <td class="">
                    {{menu.name}}
                  </td>
                  <td class="textCenter">
                    <div class="fxColumn fxRowCenter py12">
                      {{menu.createdAt | date:'dd MMM, yyyy hh:mm a'}}
                    </div>
                  </td>
                  <td class="textCenter">
                    <div class="fxColumn fxRowCenter">
                      {{menu.outlet?.name || '-'}}
                    </div>
                  </td>
                  <td class="">
                    <div class="fxColumn">
                      {{menu.description || '-'}}
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="isLoading && (recipeList.length > 0 || menuList.length > 0)"class="fxRow fxRowCenter fxColumnCenter h50">
              <img class="loading-icon" src="./assets/image/img/product/loader.gif" >
            </div>
          </div>
        </div>
    </section>
    <section>
        <app-footer></app-footer>
    </section>
</div>

