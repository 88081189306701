import { Injectable } from '@angular/core';
import {BaseService} from "../base-service/base.service";

@Injectable({
  providedIn: 'root'
})
export class AddressApiService {

  constructor(private baseService: BaseService) { }

  // get  address
  getAddress(){
    return this.baseService.get('/v1/user/get/user/addressList');
  }

  getOutletList(){
    return this.baseService.get('/v1/user/get/user/outletList');
  }
}
