<div *ngIf="menu" class="h80vh w80vw fxColumn px-2">
  <div class="mb-4 fxRow fxRowSpaceBetween fxColumnCenter">
    <h3>Menu Detail</h3>
    <i class="ti-close font20 cursorPointer" (click)="dialog.close()"></i>
  </div>
  <div class="fxColumn fullHeight p12 borderRadius">
    <div class="px12">
      <div class="fxRow p12 gap12 borderBottom">
        <div class="w20p fxRow">{{ 'Recipe Name' | translate}}</div>
        <div class="w20p fxRow">{{ 'Business Line' | translate}}</div>
        <div class="w20p fxRow">{{ 'Category' | translate}}</div>
        <div class="w20p fxRow">{{ 'Last Update' | translate}}</div>
        <div class="w20p fxRow">{{ 'Cost' | translate}}</div>
      </div>
    </div>
    <div *ngIf="menu.childList && menu.childList[0]" class="scrollY px12" style="height: calc(100% - 50px);">
      <div *ngFor="let subMenu of menu.childList">
        <div *ngFor="let child of subMenu.childList" class="fullWidth fxRow fxColumnCenter gap12 py12 borderBottom cursorPointer" (click)="onGoDetail(child)">
          <div class="w20p">{{child.name}}</div>
          <div class="w20p">{{menu.outlet?.name || '-'}}</div>
          <div class="w20p">{{child.categoryList[0].name}}</div>
          <div class="w20p">{{child.updatedAt | date:'dd MMM, yyyy hh:mm a'}}</div>
          <div class="w20p">  ${{child.totalPrice | number:'1.2-2'}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="!menu.childList || !menu.childList[0]" class="fullHeight px12 fxRow fxRowCenter fxColumnCenter noSelect textGray font18 fontSemiBold">
      {{'No Data'| translate}}
    </div>
  </div>
</div>
