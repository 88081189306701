import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import {TranslatorService} from "../../../service/translator-service/translator.service";
import {ToastrService} from "ngx-toastr";
import * as _ from "lodash";
import {MaterialApiService} from "../../../service/material-api-service/material-api.service";
import {RecipeApiService} from "../../../component/recipe/api/recipe-api.service";
import {DialogService} from "../../../native/dialog/service/dialog.service";
import {v4 as uuid} from 'uuid';
import {SideMenuService} from "../../../component/side-menu/service/side-menu.service";
import {CollectionApiService} from "../../../component/shop/collection/collection/api/collection-api.service";
import {ClientSettingApiService} from "../../../service/client-setting-api-service/client-setting-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AddressApiService} from "../../../service/address-api-service/address-api.service";

@Component({
  selector: 'app-add-recipe-dialog',
  templateUrl: './add-recipe-dialog.component.html',
  styleUrls: ['./add-recipe-dialog.component.scss']
})
export class AddRecipeDialogComponent implements OnInit, OnDestroy {
  @Input() dialog: Dialog;
  public categoryList: any;
  public categoryItemList: any[];
  public trendingItemList: any[];

  public selectedCategory: any;
  public selectedSubCategory: any;
  public selectedCategoryId: any;

  public searchWord: string;
  public searchMaterialWord: string;
  public recipeClone: any;

  public next: any;
  public isNext: boolean;

  public isLoaded: boolean;
  public loadingProduct: boolean;
  public stopLoadMore: boolean;

  public eMenuSelectSub: any;



  public isCreating: boolean;
  public error: any;
  public recipe: any;
  public errorChildItem: any;

  public paramSub: any;

  public itemMeasureSetting: any;
  public isLoadedProductList: boolean;

  public selectType: string =  'product';

  public materialList: any[];
  public nextMaterial: any;
  public isLoadedMaterialList: boolean;

  public recipeCategoryList: any[];
  public selectedRecipeCategoryId: any;
  public selectedRecipeCategoryIdClone: any;

  public outletList: any[];
  public selectedOutletId: any;
  public selectedOutletIdClone: any;
  public productionSetting: any;

  constructor(
      public sideMenuService: SideMenuService,
      private collectionApiService: CollectionApiService,
      private clientSettingApiService: ClientSettingApiService,
      private translatorService: TranslatorService,
      private toasterService: ToastrService,
      private materialApiService: MaterialApiService,
      private recipeApiService: RecipeApiService,
      private dialogService: DialogService,
      private route: ActivatedRoute,
      private router: Router,
      private outletApiService: AddressApiService
  ) {
    this.sideMenuService.isHomeScreen = true;
    this.categoryItemList = [];

    this.eMenuSelectSub = this.sideMenuService.eMenuSelect.subscribe(() => {
      this.selectedCategory = this.sideMenuService.selectedMenu;
      this.selectedSubCategory = this.sideMenuService.selectedSubMenu;
      this.getCategoryProduct();
      const element = document.getElementById('categoryScrollId');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });

    this.error = {};
    this.errorChildItem = [];
    this.materialList = [];
    this.recipe = {name: '', childList: [], status: ''};

    this.itemMeasureSetting = {};
    this.searchWord = '';
    this.searchMaterialWord = '';
    this.recipeClone = {};

    this.recipeCategoryList = [];
    this.outletList = [];

    this.onSearch = _.debounce(this.onSearch, 500);
    this.onSearchMaterial = _.debounce(this.onSearchMaterial, 500);
  }

  async ngOnInit() {
    if(!_.isEmpty(this.dialog?.options?.data)){
      this.recipe = _.cloneDeep(this.dialog.options.data);
    }
    if(this.recipe.categoryIdList){
      this.selectedRecipeCategoryId = this.recipe.categoryIdList[0];
      this.selectedRecipeCategoryIdClone = _.cloneDeep(this.selectedRecipeCategoryId);
    }
    else{
      this.recipe.categoryIdList = [];
    }
    if(this.recipe.outletId){
      this.selectedOutletId = this.recipe.outletId;
      this.selectedOutletIdClone = _.cloneDeep(this.selectedOutletId);
    }
    else{
      this.recipe.outletId = '';
    }
    this.recipeClone = _.cloneDeep(this.recipe);
    await this.getRecipeCategoryList();
    await this.getProductList();
    await this.getMaterialList();
    await this.getItemMeasureSetting();
    await this.getOutletList();
    await this.getProductionSetting();
    this.isLoaded = true;
  }

  ngOnDestroy() {
    this.eMenuSelectSub.unsubscribe();
    if (this.paramSub) { this.paramSub.unsubscribe(); }
  }

  async getProductionSetting(): Promise<void> {
    try {
      this.productionSetting = await this.recipeApiService.getProductionSetting().toPromise();
    } catch (e) {
      console.error('get production setting failed!', e);
    }
  }

  async getProductList(): Promise<void> {
    this.isLoadedProductList = false;
    try {
      if (!this.selectedCategoryId) { this.selectedCategoryId = 'ALL'; }
      const response = await this.recipeApiService.getProductListForRecipe(24, 0, {search: this.searchWord},
          this.selectedCategoryId).toPromise();

      if (!this.categoryList) {
        this.categoryList = response.categoryList;
      }

      this.categoryItemList = response.list;

      this.next = response.next;
    } catch (e) {
      console.error('get product failed!', e);
    }
    this.isLoadedProductList = true;
  }

  async getMoreProductList(): Promise<void> {
      if(!this.next){return ;}
      try {
          if (!this.selectedCategoryId) { this.selectedCategoryId = 'ALL'; }
          const response = await this.recipeApiService.getProductListForRecipe(24, this.next, {search: this.searchWord},
              this.selectedCategoryId).toPromise();

          if (!this.categoryList) {
              this.categoryList = response.categoryList;
          }

          for (const category of response.list) {
              const categoryItem = this.categoryItemList.find(categoryItem => categoryItem._id === category._id);
              if (categoryItem && categoryItem.itemList) {
                  categoryItem.itemList = categoryItem.itemList.concat(category.itemList);
              } else {
                  this.categoryItemList.push(category);
              }
          }

         this.next = response.next;
      } catch (e) {
          console.error('get more product failed!', e);
      }
  }

  async getMaterialList(): Promise<void> {
    this.isLoadedMaterialList = false;
    try {
      const response = await this.materialApiService.getMaterialList(20, 0, {search: this.searchMaterialWord}).toPromise();
      this.materialList = response.list;
      this.nextMaterial = response.next;
    } catch (e) {
      console.error('get material list failed!', e);
    }
    this.isLoadedMaterialList = true;
  }

  async getOutletList(): Promise<void> {
    this.isLoadedMaterialList = false;
    try {
      this.outletList = await this.outletApiService.getOutletList().toPromise();
      if(!this.selectedOutletId){
        this.selectedOutletId = this.outletList[0]._id;
        this.selectedOutletIdClone = _.cloneDeep(this.selectedOutletId);
      }
    } catch (e) {
      console.error('get outlet list failed!', e);
    }
    this.isLoadedMaterialList = true;
  }

  async getRecipeCategoryList(): Promise<void> {
    this.isLoadedMaterialList = false;
    try {
      const response = await this.recipeApiService.getRecipeCategoryList().toPromise();
      this.recipeCategoryList = response.list;
      if(!this.selectedRecipeCategoryId){
        this.selectedRecipeCategoryId = this.recipeCategoryList[0]._id;
        this.selectedRecipeCategoryIdClone = _.cloneDeep(this.selectedRecipeCategoryId);
      }
    } catch (e) {
      console.error('get recipe type list failed!', e);
    }
    this.isLoadedMaterialList = true;
  }

  async getMoreMaterialList(): Promise<void> {
    if(!this.nextMaterial){return ;}
    try {
      const response = await this.materialApiService.getMaterialList(20, this.nextMaterial, {search: this.searchMaterialWord}).toPromise();
      this.materialList = this.materialList.concat(response.list);
      this.nextMaterial = response.next;
    } catch (e) {
      console.error('get more material failed!', e);
    }
  }

  async getCategoryProduct(): Promise<void> {

    this.selectedCategoryId = this.selectedSubCategory ? this.selectedSubCategory._id : this.selectedCategory._id;
    await this.getProductList();
    this.loadingProduct = false;
  }

  async getItemMeasureSetting(): Promise<void>{
    try {
      this.itemMeasureSetting = await this.recipeApiService.getItemMeasureSetting().toPromise();
    }catch(e){
      console.error("fail item measure setting", e);
    }
  }

  public async onSaveDaft() : Promise<void> {
    this.isCreating = true;
    try {
      this.recipe.status = 'draft';
      this.recipe.categoryIdList[0] =  this.selectedRecipeCategoryId;
      this.recipe.outletId =  this.selectedOutletId;
      if(this.recipe._id){
        await this.recipeApiService.updateRecipe(this.recipe._id, this.recipe).toPromise();
      } else{
        this.recipe = await this.recipeApiService.createRecipe(this.recipe).toPromise();
      }
      const toasterText = await this.translatorService.translate('Recipe is draft successfully');
      this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
      this.recipeClone = _.cloneDeep(this.recipe);
      this.selectedRecipeCategoryIdClone = _.cloneDeep(this.selectedRecipeCategoryId);
    } catch(e) {
      const toasterText = await this.translatorService.translate('Draft recipe fail');
      this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
      console.error('fail to draft recipe', e);
    }
    this.isCreating = false;
  }

  public async onAddRecipe() : Promise<void> {
    if (!this.validateItem()) { return; }
    this.isCreating = true;
    try {
      this.recipe.status = 'pending';
      this.recipe.outletId =  this.selectedOutletId;
      this.recipe.categoryIdList[0] =  this.selectedRecipeCategoryId;
      await this.recipeApiService.updateRecipe(this.recipe._id, this.recipe).toPromise();
      const toasterText = await this.translatorService.translate('Recipe is created successfully');
      this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
      this.onClose(this.recipe);
    } catch(e) {
      const toasterText = await this.translatorService.translate('Create recipe fail');
      this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
      console.error('fail to create recipe', e);
    }
    this.isCreating = false;
  }
  public async onUpdateRecipe(): Promise<void>{
    if (!this.validateItem()) { return; }
    this.isCreating = true;
    this.recipe.outletId =  this.selectedOutletId;
    this.recipe.categoryIdList[0] =  this.selectedRecipeCategoryId;
    try {
      await this.recipeApiService.updateRecipe(this.recipe._id, this.recipe).toPromise();
      const toasterText = await this.translatorService.translate('Recipe is updated successfully');
      this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
      this.onClose(this.recipe);
    } catch(e) {
      const toasterText = await this.translatorService.translate('Update recipe fail');
      this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
      console.error('fail to create recipe', e);
    }
    this.isCreating = false;
  }

  public onInputChange(name: string): void {
    this.error[name] = false;
  }

  private validateItem(): boolean {
    this.error.name = _.isEmpty(this.recipe.name.trim());
    this.error.outlet = (!this.selectedOutletId || _.isEmpty(this.outletList.filter(outlet => outlet._id === this.selectedOutletId)));
    this.error.category = (!this.selectedRecipeCategoryId || _.isEmpty(this.recipeCategoryList.filter(category => category._id === this.selectedRecipeCategoryId)));
    // this.error.itemList = _.isEmpty(this.recipe.itemList);
    this.error.childList = _.isEmpty(this.recipe.childList);
    this.errorChildItem = [];
    let index = 0;
    for (const child of this.recipe.childList) {
      if (child.type === 'item' && (child.name.trim() === '' || _.isEmpty(String(child.qty).trim()) || !child?.qty
          || _.isEmpty(String(child.useUnitOfMeasurement.trim()))) || !this.getItemMeasure(child.packing).includes(child.useUnitOfMeasurement)) {
        const e = {
          name: child.name.trim() === '',
          qty: _.isEmpty(String(child.qty).trim()) || !child?.qty,
          measure: _.isEmpty(String(child.useUnitOfMeasurement)) || !this.getItemMeasure(child.packing).includes(child.useUnitOfMeasurement),
        };
        this.errorChildItem.splice(index, 0, e);
      } else {
        this.errorChildItem.splice(index, 0, {name: false, qty: false, measure: false});
      }
      index ++;
    }
    for (const e of this.errorChildItem){
      if (e.name || e.qty || e.measure){
        return false;
      }
    }
    for (const key in this.error) {
      if (this.error.hasOwnProperty(key)) {
        if (this.error[key]) {
          return false;
        }
      }
    }
    return true;
  }

  onRemoveItem(child: any, index: number): void{
    this.recipe.childList = this.recipe.childList.filter(c => c._id !==  child._id);
    this.errorChildItem.splice(index, 1);
  }

  onInput(index: number , key: string): void {
    if (key === 'name' && this.errorChildItem[index] ){
      this.errorChildItem[index].name = false;
    }
    if (key === 'qty' && this.errorChildItem[index]){
      this.errorChildItem[index].qty = false;
    }
    if (key === 'measure' && this.errorChildItem[index]){
      this.errorChildItem[index].measure = false;
    }
  }
  onCustomProduct(): void {
    // check if there are empty
    for (const child of this.recipe.childList) {
      if(child.isNeedValidate){
        if (child.type === 'item' && (child.name.trim() === '' || _.isEmpty(String(child.qty).trim()) || !child?.qty
          || _.isEmpty(String(child.useUnitOfMeasurement.trim())))) {
          const errorName = child.name.trim() === '';
          const errorQty = _.isEmpty(String(child.qty).trim()) || !child?.qty;
          const errorMeasure = _.isEmpty(String(child.useUnitOfMeasurement));
          if(errorName || errorQty || errorMeasure){ return;}
        }
      }
    }

    const newCustomItem = {
        _id: uuid(),
        name: '',
        qty: '',
        useUnitOfMeasurement: '',
        isNeedValidate: true,
        packing: 'Weight',
        type: 'item'
    };
    this.recipe.childList.push(newCustomItem);
    this.onInputChange('childList');
  }

  onClickItem(event): void{
    if(event.isAdd){
      const newAddItem = {
        _id: event.item._id,
        name: event.item.name,
        useUnitOfMeasurement: event.item.unitOfMeasurement ?? '',
        unitOfMeasurement: event.item.unitOfMeasurement ?? '',
        packing: event.item.packing ?? '',
        pieceInPacket: event.item.pieceInPacket ?? '',
        packetInCarton: event.item.packetInCarton ?? '',
        qty: 1,
        type: 'item',
        sku: event.item.sku,
        itemSupplierList: event.item.itemSupplierList

      };
      this.recipe.childList.push(newAddItem);
      this.onInputChange('childList');
    } else{
      this.recipe.childList = this.recipe.childList.filter(child => child._id !== event.item._id);
    }

  }
  isItemSelected(item): boolean{
    return this.recipe.childList?.some(child=> child._id === item._id);
  }

  getItemMeasure(measureType: string): string[]{
    let matchedObject = this.itemMeasureSetting.itemMeasureTypeList.find(m => m.code === measureType);
    if (matchedObject) {
      return matchedObject.unitList.map(sub => sub.code);
    }
    return this.itemMeasureSetting.itemMeasureTypeList.map(measure => measure.unitList.map(sub => sub.code)).flat();
  }
  onSearch(): void{
    this.getProductList().then();
  }
  onSearchMaterial(): void{
    this.getMaterialList().then();
  }

  onClose(data?: any): void{
    this.dialog.close(data, {disableClose: false});
  }

  onCancel(): void{
    if(this.isChangeData() && ((!this.recipe._id && this.recipe.status !== 'draft') || (this.recipe._id && this.recipe.status === 'draft'))){
      const confirmDialog = this.dialogService.open('confirmDialog', {
        data: {
          title: 'Confirm',
          description: 'Are you want save draft this recipe ?'
        },
        disableClose: true
      });
      const confirmSub = confirmDialog.eClose.subscribe(async (data) => {
        if (data) {
          await this.onSaveDaft();
        }
        this.onClose(this.recipeClone);
        confirmSub.unsubscribe();
      });
    } else{
      this.onClose(this.recipe);
    }
  }

  isChangeData(): boolean{
    return !_.isEqual({...this.recipe, categoryId: this.selectedRecipeCategoryId, outLet: this.selectedOutletId}, {...this.recipeClone, categoryId: this.selectedRecipeCategoryIdClone, outLet: this.selectedOutletIdClone});
  }
  isHaveProduct(): boolean{
    return !_.isEmpty(this.recipe.childList.filter(child => !child?.isNeedValidate));
  }
  isHaveCustomProduct(): boolean{
    return !_.isEmpty(this.recipe.childList.filter(child => child?.isNeedValidate));
  }
  onAddMaterial(): void{
    const addMaterialDialog =  this.dialogService.open('addMaterialDialog', { });
    const addMaterialSub = addMaterialDialog.eClose.subscribe(material => {
      if (material) {
        this.getMaterialList().then();
      }
      addMaterialSub.unsubscribe();
    });
  }
  onEditMaterial(event:any, material): void{
    event.stopPropagation();
    const addMaterialDialog =  this.dialogService.open('addMaterialDialog', {data: material});
    const addMaterialSub = addMaterialDialog.eClose.subscribe(material => {
      if (material) {
        this.getMaterialList().then();
      }
      addMaterialSub.unsubscribe();
    });
  }

  onDeleteMaterial(event:any, material): void{
    event.stopPropagation();
    const confirmDialog = this.dialogService.open('confirmDialog', {
      data: {
        description: 'Are you sure you want to delete this material ?'
      }
    });
    const confirmSub = confirmDialog.eClose.subscribe(async (data) => {
      if (data) {
        try {
          await this.materialApiService.updateMaterial(material._id, {isDeleted: true}).toPromise();
          this.materialList = this.materialList.filter(mat => mat._id !== material._id);
          const toasterText = await this.translatorService.translate('Material have been deleted');
          this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
        }catch (e) {
          const toasterText = await this.translatorService.translate('Delete Material fail');
          this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
        }
      }
      confirmSub.unsubscribe();
    });
  }
  onClickMaterial(material): void{
    if(!['accepted'].includes(material.status) && this.productionSetting.semiProductApproval?.isEnabled){ return;}
    if(!this.isMaterialSelected(material)){
      material.type = 'material';
      material.qty = 1;
      material.useUnitOfMeasurement = this.getItemMeasure(material.packing)[0];
      this.recipe.childList.push(material);
      this.onInputChange('childList');
    } else{
      this.recipe.childList = this.recipe.childList.filter(child => child._id !== material._id);
    }
  }
  isMaterialSelected(material): boolean{
    return this.recipe.childList?.some(child => child._id === material._id);
  }
}


