<div class="w90vw h90vh fxColumn px-4 py-2 gap24">
  <div class="fxRow fxRowSpaceBetween">
      <h3 class="w15p noSelect">{{ (this.dialog.options?.data ? 'Edit Recipe' : 'Add Recipe')| translate }}</h3>
      <div class="w85p fxRow" style="gap: 32px">
        <div class="fxRow fullWidth w250">
          <div class="w50p fxRow fxRowCenter fontBold px12 pt12 border cursorPointer noSelect" (click)="selectType = 'product'; searchMaterialWord = ''" [class.backgroundPrimary]="selectType === 'product'" [class.colorWhite]="selectType === 'product'">{{'Product'}}</div>
          <div class="w50p fxRow fxRowCenter fontBold px12 pt12 border cursorPointer noSelect" (click)="selectType = 'material'; searchWord = ''" [class.backgroundPrimary]="selectType === 'material'" [class.colorWhite]="selectType === 'material'">{{'Semi-Product'}}</div>
        </div>
        <form *ngIf="selectType === 'product'" class="form_search rounded-1 w450">
              <input id="query search-autocomplete"
                     name="search-product"
                     [placeholder]="'header_search_hint'| translate" class="nav-search nav-search-field"
                     [(ngModel)]="searchWord"
                     (input)="onSearch()">
              <button type="submit" class="btn-search">
                  <i class="ti-search"></i>
              </button>
          </form>
        <form *ngIf="selectType === 'material'" class="form_search rounded-1 w450">
          <input id="query search-autocomplete"
                 name="search-product"
                 [placeholder]="'Search any Semi-Product...'| translate" class="nav-search nav-search-field"
                 [(ngModel)]="searchMaterialWord"
                 (input)="onSearchMaterial()">
          <button type="submit" class="btn-search">
            <i class="ti-search"></i>
          </button>
        </form>
      </div>
  </div>
  <div class="fadeIn fxColumn fullHeight" *ngIf="isLoaded">
      <!-- product listing section start -->
      <div class="fxRow" style="height: calc(100vh - 212px);">
        <div class="fxColumn w70p fullHeight">
          <div *ngIf="selectType === 'product'" class="fxRow fullHeight fullWidth">
            <div class="w30p fullHeight">
              <app-side-menu [items]="categoryList"></app-side-menu>
            </div>
            <div *ngIf="isLoadedProductList"
                 infiniteScroll
                 [infiniteScrollUpDistance]="2"
                 [infiniteScrollThrottle]="50"
                 [scrollWindow]="false"
                 (scrolled)="getMoreProductList()"
                 class="w70p h-no-header tab-content scrollY" style="border-right: 1px solid #ddd;"
            >
              <div class="tab-pane fade show active px-3" id="grocery">
                <div id="categoryScrollId"></div>
                <div *ngIf="categoryItemList.length > 0">
                  <ng-container *ngFor="let categoryItem of categoryItemList; let i = index;">
                    <div *ngIf="!loadingProduct && categoryItem.itemList && categoryItem.itemList[0]">
                      <div class="title8 mt24">
                        <div class="fxRow fxRowSpaceBetween fxColumnCenter">
                          <div class="fxColumn">
                            <h2>{{ categoryItem?.label || '-' }}</h2>
                            <p *ngIf="categoryItem.description">{{ categoryItem.description }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="row g-sm-4 g-3">
                        <app-product-select
                          *ngFor="let product of categoryItem.itemList"
                          [product]="product"
                          [loader]="loadingProduct"
                          (eItemClick)="onClickItem($event)"
                          [isSelected]="isItemSelected(product)"
                          class = "w30p"
                        ></app-product-select>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div *ngIf="categoryItemList.length <= 0" class="h450 fullWidth fxRow fxRowCenter fxColumnCenter">
                  <div>
                    <div class="fxRow fxRowCenter textGray font18 noSelect" style="padding-bottom: 64px">{{'No Product'}}</div>
                    <button  class="btn btn-solid" (click)="onCustomProduct()">
                      {{ 'Add Custom ingredient' | translate }}
                    </button>
                  </div>
                </div>

              </div>
            </div>
            <div *ngIf="!isLoadedProductList" class="w70p h-no-header tab-content fxRow fxColumnCenter fxRowCenter">
              <img class="loading-icon" src="./assets/image/img/product/loader.gif" >
            </div>
          </div>
          <div *ngIf="selectType === 'material'" class="fullHeight fullWidth mt12">
            <div *ngIf="isLoadedMaterialList"
                 infiniteScroll
                 [infiniteScrollUpDistance]="2"
                 [infiniteScrollThrottle]="50"
                 [scrollWindow]="false"
                 (scrolled)="getMoreMaterialList()"
                 class="h-no-header tab-content scrollY" style="border-right: 1px solid #ddd;"
            >
              <div class="tab-pane fade show active px-3" id="grocery">
                <div id="categoryScrollId"></div>
                <div>
                  <ng-container>
                    <div class="row g-sm-4 g-3">
                      <div *ngFor="let material of materialList; let i = index;" class="w50p">
                        <div class="product-box product-style-5 fullHeight gap12 noSelect" [class.cursorPointer]="!productionSetting.semiProductApproval?.isEnabled || ['accepted'].includes(material.status)" (click)="onClickMaterial(material)">
                          <div class="fxRow fxRowSpaceBetween">
                            <div class="pt-2 font20 fontBold">{{ material?.name | titlecase }}</div>
                            <app-check-box *ngIf="!productionSetting.semiProductApproval?.isEnabled || ['accepted'].includes(material.status)" [disable]="true" [checked]="isMaterialSelected(material)"></app-check-box>
                            <div *ngIf="productionSetting.semiProductApproval?.isEnabled && ['pending'].includes(material.status)" class="status-container status-pending">{{'PENDING' | translate}}</div>
                            <div *ngIf="productionSetting.semiProductApproval?.isEnabled && ['declined'].includes(material.status)" class="status-container status-declined">{{'DECLINED' | translate}}</div>
                          </div>
                          <div class="font16 fontBold pt6 pb12">${{material.totalPrice | number: '1.2-2'}}</div>
                          <div *ngFor="let child of material.childList" class="fxRow gap6 fullWidth">
                            <div class="w5p fxRow fxRowEnd fontBold">-</div>
                            <div class="w60p">{{child.name}}</div>
                            <div class="w20p">{{child.qty}} {{child.useUnitOfMeasurement}}</div>
                            <div class="w15p">${{child.totalPrice | number: '1.2-2'}}</div>
                          </div>
                          <div class="fxRow fxRowEnd gap12 pt12">
                            <div class="cursorPointer" (click)="onEditMaterial($event, material)">
                              <svg-icon src="./assets/image/icon/recipe/edit-icon.svg"
                                        class="size18 colorGray"></svg-icon>
                            </div>
                            <div class="cursorPointer" (click)="onDeleteMaterial($event, material)">
                              <svg-icon src="./assets/image/icon/material/delete-icon.svg"
                                        class="size18 colorGray"></svg-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </ng-container>
                </div>
                <div *ngIf="materialList.length <= 0" class="h450 fullWidth fxRow fxRowCenter fxColumnCenter">
                  <div>
                    <div class="fxRow fxRowCenter textGray font18 noSelect" style="padding-bottom: 64px">{{'No Semi-Product'}}</div>
                    <button class="btn btn-solid" (click)="onAddMaterial()">{{'Add Semi-Product'}}</button>
                  </div>
                </div>
                <div *ngIf="materialList.length > 0" class="fxRow fxRowCenter p24">
                  <button class="btn btn-solid" (click)="onAddMaterial()">{{'Add Semi-Product'}}</button>
                </div>
              </div>
            </div>
            <div *ngIf="!isLoadedMaterialList" class="h-no-header tab-content fxRow fxColumnCenter fxRowCenter">
              <img class="loading-icon" src="./assets/image/img/product/loader.gif" >
            </div>
          </div>
        </div>
        <div class="w30p fullHeight fxColumn pl12 scrollY" style="height: calc(100% - 16px)">
              <div class="mb-3">
                  <label for="form-input-name" class="form-label">{{ 'Recipe name' | translate }}</label>
                  <input id="form-input-name"
                         [(ngModel)]="recipe.name"
                         [class.is-invalid]="error.name"
                         (input)="onInputChange('name')"
                         class="form-control"
                         [placeholder]="'Recipe name' | translate"
                  />
                <label for="form-input-name" class="form-label">{{ 'Business Line' | translate }}</label>
                <select [(ngModel)]="selectedOutletId" [class.is-invalid]="error.outlet"
                        (change)="onInputChange('outlet')" class="form-control fullWidth" placeholder="Category" style="appearance: auto !important;">
                  <option *ngFor="let outlet of outletList" [value]="outlet._id">{{outlet.name}}</option>
                </select>
                <label for="form-input-name" class="form-label">{{ 'Category' | translate }}</label>
                <select [(ngModel)]="selectedRecipeCategoryId" [class.is-invalid]="error.category"
                        (change)="onInputChange('category')" class="form-control fullWidth" placeholder="Category" style="appearance: auto !important;">
                  <option *ngFor="let category of recipeCategoryList" [value]="category._id">{{category.name}}</option>
                </select>
              </div>
              <div class="mb-4 fxColumn backgroundOver p12 borderRadius">
                  <div class="fxRow fxColumnCenter">
                      <label for="form-input-subject" class="form-label">{{ 'Ingredients' | translate }}</label>
                      <label *ngIf="error.childList" for="form-input-subject" class="form-label colorDanger ml12 font12">{{ 'Please add at least one ingredient' | translate }}</label>
                  </div>
                  <div *ngIf="!isHaveProduct()" class="fullHeight fxRow fxRowCenter fxColumnCenter h150">{{'No Data'}}</div>
                  <div *ngIf="isHaveProduct()" class="fullHeight scrollY borderRadius h150" [class.borderDanger]="error.childList">
                      <div *ngFor="let child of recipe.childList; let index = index;">
                        <div *ngIf="!child?.isNeedValidate" class="b12 p6">
                          <div class="fxRow fxColumnCenter fxRowSpaceBetween borderRadius" style="gap: 12px">
                            <div  class="fontSemiBold w40p">{{child.name}}</div>
                            <input [(ngModel)]="child.qty" type="number" min="1" pattern="^[0-9]" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                                   [class.is-invalid]="errorChildItem[index] && errorChildItem[index].qty" placeholder="Amount" (input)="onInput(index ,'qty')"
                                   class="form-control w25p"
                            />
                            <select [(ngModel)]="child.useUnitOfMeasurement" [class.is-invalid]="errorChildItem[index] && errorChildItem[index].measure"
                                    (change)="onInput(index ,'measure')" class="form-control w30p" style="appearance: auto !important;">
                              <option *ngFor="let uom of getItemMeasure(child.packing)" [value]="uom">{{uom}}</option>
                            </select>
                            <div class="w10p fxRow fxRowCenter fxColumnCenter">
                              <div class="cursorPointer" (click)="onRemoveItem(child, index)">
                                <svg-icon src="./assets/image/icon/shop/cart/delete-filled-icon.svg"
                                          class="size20"></svg-icon>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                  </div>
              </div>

              <div class="mb-4 fxColumn borderRadius p12 backgroundOver">
                  <div class="fxRow fxColumnCenter">
                      <label for="form-input-subject" class="form-label">{{ 'Custom Ingredients' | translate }}</label>
                  </div>
                  <div *ngIf="!isHaveCustomProduct()" class="fullHeight fxRow fxRowCenter fxColumnCenter h150">{{'No Data'}}</div>
                  <div *ngIf="isHaveCustomProduct()" class="fullHeight scrollY borderRadius h150">
                      <div *ngFor="let child of recipe.childList; let index = index;">
                          <div *ngIf="child?.isNeedValidate" class="fxRow fxColumnCenter fxRowSpaceBetween py6 borderRadius mb12" style="gap: 12px;">
                              <input [(ngModel)]="child.name"
                                     [class.is-invalid]="errorChildItem[index] && errorChildItem[index].name" placeholder="Name"
                                     class="form-control w35p" (input)="onInput(index ,'name')"
                              />
                              <input [(ngModel)]="child.qty" type="number" min="1" pattern="^[0-9]" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                                     [class.is-invalid]="errorChildItem[index] && errorChildItem[index].qty" placeholder="Amount" (input)="onInput(index ,'qty')"
                                     class="form-control w25p"
                              />
                              <select [(ngModel)]="child.unitOfMeasurement" [class.is-invalid]="errorChildItem[index] && errorChildItem[index].measure"
                                      (change)="onInput(index ,'measure')" class="form-control w30p" placeholder="Measure" style="appearance: auto !important;">
                                  <option *ngFor="let uom of getItemMeasure(child.unitOfMeasurement)" [value]="uom">{{uom}}</option>
                              </select>
                              <div class="w10p fxRow fxRowCenter fxColumnCenter">
                                  <div class="cursorPointer" (click)="onRemoveItem(child, index)">
                                      <svg-icon src="./assets/image/icon/shop/cart/delete-filled-icon.svg"
                                                class="size20"></svg-icon>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="fxRow fxRowEnd gap12">
          <button class="btn btn-solid" (click)="onCancel()">
              {{ 'Cancel' | translate }}
          </button>
          <button *ngIf="(!recipe._id && recipe.status !== 'draft') || (recipe._id && recipe.status === 'draft')" class="btn btn-solid" (click)="onSaveDaft()" [disabled]="isCreating || !isChangeData() || recipe.status === 'pending'">
              {{ 'Save Draft' | translate }}
          </button>
          <button *ngIf="(!recipe._id) || (recipe._id && recipe.status === 'draft')" class="btn btn-solid" (click)="onAddRecipe()" [disabled]="isCreating || recipe?.childList?.length <= 0 || recipe.status !== 'draft'">
              {{ 'Create' | translate }}
          </button>
          <button *ngIf="recipe._id && recipe.status !== 'draft'" class="btn btn-solid" (click)="onUpdateRecipe()" [disabled]="isCreating || recipe?.childList?.length <= 0 || !isChangeData()">
              {{ 'Update' | translate }}
          </button>
          <app-loader-overlay *ngIf="isCreating"></app-loader-overlay>
      </div>
  </div>

  <div *ngIf="!isLoaded" class="fullHeight">
      <!-- product listing section start -->
      <div class="fullHeight">
          <div class="row">
              <div class="col-3">
                  <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'width': '320px', 'border-radius': '0', height: '80vh' }"></ngx-skeleton-loader>
              </div>

              <div class="col-6 px-3">
                  <div class="row g-sm-4 g-3">
                      <app-skeleton-product-box
                              *ngFor="let product of [].constructor(4)"
                              class="col-xxl-3"
                      ></app-skeleton-product-box>
                  </div>
              </div>
              <div class="col-2">
                  <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'width': '320px', 'border-radius': '0', height: '80vh', 'padding-lef': '90px' }"></ngx-skeleton-loader>
              </div>

          </div>
      </div>
      <!-- product listing section end -->
  </div>
</div>
