import {Component, OnInit} from '@angular/core';
import {DialogService} from "../../../native/dialog/service/dialog.service";
import {Breadcrumb} from "../../../native/breadcrumb/model/breadcrumb";
import {TranslatorService} from "../../../service/translator-service/translator.service";
import {Router} from "@angular/router";
import {RecipeApiService} from "../api/recipe-api.service";
import {ToastrService} from "ngx-toastr";
import * as _ from 'lodash';
import {UserService} from "../../../native/user/service/user.service";

@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss']
})
export class RecipeComponent implements OnInit{
  public breadCrumb: Breadcrumb;
  public isLoading: boolean;
  public next: number;
  public isNext: boolean;
  public recipeList : any[];

  public menuList: any[];

  public query: {search: string };
  public selectedType: 'recipe' | 'menu';

  constructor(
      private translatorService: TranslatorService,
      private dialogService: DialogService,
      private router: Router,
      private recipeApiService: RecipeApiService,
      private toasterService: ToastrService,
      public userService: UserService
  ){
    this.recipeList = [];
    this.menuList = [];
    this.next = 0;
    this.query = {search: '' };
    this.selectedType = 'recipe';
    this.onSearch = _.debounce(this.onSearch, 500);
  }

  async ngOnInit() {
    await this.initBreadcrumb();
    await this.getRecipeList();
  }
  private async initBreadcrumb() {
    this.breadCrumb = new Breadcrumb({
      title: await this.translatorService.translate('ticket_breadcrumb_title'),
      breadcrumbList: [
        {
          label: await this.translatorService.translate('RECIPE')
        }
      ]
    });
  }
  private async getRecipeList() : Promise<void> {
    this.recipeList = [];
    try {
      this.isLoading = true;
      const {list, next} =  await this.recipeApiService.getRecipeList(0,15, this.query).toPromise();
      this.recipeList = this.recipeList.concat(list);
      this.next = next;
      this.isNext = next !== null;
      this.isLoading = false;
    } catch(e) {
      this.isLoading = false;
      console.error('fail to get recipe', e);
    }
  }
  private async getMoreRecipeList() : Promise<void> {
    try {
      this.isLoading = true;
      const {list, next} =  await this.recipeApiService.getRecipeList(this.next,15, this.query).toPromise();
      this.recipeList = this.recipeList.concat(list);
      this.next = next;
      this.isNext = next !== null;
      this.isLoading = false;
    } catch(e) {
      this.isLoading = false;
      console.error('fail to get recipe', e);
    }
  }

  private async getMenuList() : Promise<void> {
    this.menuList = [];
    try {
      this.isLoading = true;
      const {list, next} =  await this.recipeApiService.getMenuList(0,15, this.query).toPromise();
      this.menuList = this.menuList.concat(list);
      this.next = next;
      this.isNext = next !== null;
      this.isLoading = false;
    } catch(e) {
      this.isLoading = false;
      console.error('fail to get menu', e);
    }
  }
  private async getMoreMenuList() : Promise<void> {
    try {
      this.isLoading = true;
      const {list, next} =  await this.recipeApiService.getMenuList(this.next,15, this.query).toPromise();
      this.menuList = this.menuList.concat(list);
      this.next = next;
      this.isNext = next !== null;
      this.isLoading = false;
    } catch(e) {
      this.isLoading = false;
      console.error('fail to get menu', e);
    }
  }

  public async onScroll(e) {
    const dom = e.target;
    if (dom.scrollHeight - (dom.scrollTop + dom.offsetHeight) < 400) {
      if (this.isNext && !this.isLoading) {
        if(this.selectedType === 'recipe'){
          await this.getMoreRecipeList();
        } else{
          await this.getMoreMenuList();
        }
      }
    }
  }
  public capitalizeFirstLetter(text: string): string {
    text = text ? text.charAt(0).toUpperCase() + text.substr(1).toLowerCase() : '';
    return text;
  }

  onAddRecipe(): void{
    const addRecipeDialog = this.dialogService.open('addRecipeDialog', {disableClose: true});
    const addRecipeSub = addRecipeDialog.eClose.subscribe(async data => {
      this.next = 0;
      this.recipeList = [];
      await this.getRecipeList();
      addRecipeSub.unsubscribe();
    });
  }
  onEdit(e: any, recipe: any){
    e.stopPropagation();
    const addRecipeDialog = this.dialogService.open('addRecipeDialog', {data: recipe, disableClose: true} );
    const addRecipeSub = addRecipeDialog.eClose.subscribe(data => {
      if (data) {
        this.getRecipeList().then();
      }
      addRecipeSub.unsubscribe();
    });
  }
  onDelete(e: any, recipe: any){
    e.stopPropagation();
    const confirmDialog = this.dialogService.open('confirmDialog', {
      data: {
        description: 'Are you sure you want to delete this recipe ?'
      }
    });
    const confirmSub = confirmDialog.eClose.subscribe(async (data) => {
      if (data) {
        try {
          await this.recipeApiService.deleteRecipe(recipe._id).toPromise();
          this.recipeList = this.recipeList.filter(rec => rec._id !== recipe._id);
          const toasterText = await this.translatorService.translate('Recipe have been deleted');
          this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
        }catch (e) {
          const toasterText = await this.translatorService.translate('Delete Recipe fail');
          this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
        }
      }
      confirmSub.unsubscribe();
    });
  }
  onRefill(e: any,recipe: any){
    e.stopPropagation();
    const selectAddressDialog = this.dialogService.open('selectAddressDialog',);
    const selectAddressSub = selectAddressDialog.eClose.subscribe(async (deliveryInfo) => {
      if (deliveryInfo) {
        try {
          await this.recipeApiService.refillRecipe(recipe._id, deliveryInfo).toPromise();
          const toasterText = await this.translatorService.translate('Refill succeed');
          this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
        }catch (e) {
          const toasterText = await this.translatorService.translate('Refill fail');
          this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
        }
      }
      selectAddressSub.unsubscribe();
    });
  }
  onGoDetail(recipe: any): void{
    this.dialogService.open('recipeDetailDialog', recipe);
  }

  onSearch(): void{
    if(this.selectedType === 'recipe'){
      this.getRecipeList().then();
    } else{
      this.getMenuList().then();
    }
  }
  // isFilter(): boolean{
  //   return !_.isEmpty(this.query.menuIdList);
  // }

  // onOpenFilter(): void{
  //   const filterRecipeDialog = this.dialogService.open('filterRecipeDialog', {data:  {menuIdList: this.query.menuIdList}});
  //   const filterRecipeSub = filterRecipeDialog.eClose.subscribe(async data => {
  //     if (data) {
  //       this.query.menuIdList = data.menuIdList;
  //       await this.getRecipeList();
  //     }
  //     filterRecipeSub.unsubscribe();
  //   });
  // }

  onToggleButton(key): void{
    if (this.selectedType === key){ return; }
    this.selectedType = key;
    this.query.search = '';
    this.recipeList = [];
    this.menuList = [];
    if (this.selectedType === 'recipe'){
      this.getRecipeList().then();
    } else{
      this.getMenuList().then();
    }
  }
  onGoMenuDetail(menu): void{
    this.dialogService.open('menuDetailDialog', menu);
  }
}
