import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(list: any[], keyWord: string, properties?): any[] {
    if (_.isEmpty(keyWord)) { return list; }

    const lowercaseKeyWord = keyWord.toLowerCase().trim();

    return list.filter(item => {
      if (!_.isEmpty(properties)) {
        for (const property of properties) {
          if (item[property].toLowerCase().trim().includes(lowercaseKeyWord)) { return true; }
        }
      } else {
        for (const key in item) {
          if (item[key].toLowerCase().trim().includes(lowercaseKeyWord)) { return true; }
        }
      }
    });
  }

}
