import {Component, OnDestroy, OnInit} from '@angular/core';
import {Product} from '../../../../../model/product/product';
import {ProductDetailsMainSlider, ProductDetailsThumbSlider} from '../../../../../data/slider';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../../../../../service/product-service/product.service';
import {Breadcrumb} from '../../../../../native/breadcrumb/model/breadcrumb';
import {TranslatorService} from '../../../../../service/translator-service/translator.service';
import { BasketApiService } from 'src/app/component/shop/basket/api/basket-api.service';
import {lastValueFrom} from "rxjs";
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../../../native/user/service/user.service';
import {BasketService} from '../../../basket/service/basket.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit, OnDestroy {
  public productId: string;
  public product: Product;
  public latestProduct: Product[];
  
  public counter: number ;
  public activeSlide: any;
  public mobileSidebar: boolean ;
  
  public ProductDetailsMainSliderConfig: any;
  public ProductDetailsThumbConfig: any;

  public breadcrumb: Breadcrumb;
  public paramSub: any;
  
  public isLoaded: boolean;
  public isAddingItemToCart: boolean;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translatorService: TranslatorService,
    public productService: ProductService,
    private basketApiService: BasketApiService,
    private toasterService: ToastrService,
    public userService: UserService,
    private basketService: BasketService
  ) {
    this.product = new Product();
    
    this.counter = 1;
    this.activeSlide = 0;
    this.mobileSidebar = false;
    
    this.ProductDetailsMainSliderConfig = ProductDetailsMainSlider;
    this.ProductDetailsThumbConfig = ProductDetailsThumbSlider;
    
    this.breadcrumb = new Breadcrumb();
  }
  
  async ngOnInit() {
    this.paramSub = this.route.params.subscribe(params => {
      this.productId = params.productId;
      this.getProduct().then();
    });
    await this.getLatestProduct();
  }
  
  ngOnDestroy() {
    if (this.paramSub) { this.paramSub.unsubscribe(); }
  }

  async getProduct(): Promise<void> {
    this.isLoaded = false;
    try {
      const product = await lastValueFrom(this.productService.getProductById(this.productId));
      this.product = product;
      this.ProductDetailsMainSliderConfig.loop = product.thumbnails?.length > 1;
      this.ProductDetailsMainSliderConfig.mouseDrag = product.thumbnails?.length > 1;
      await this.initBreadCrumb(product);
    } catch (e) {
      console.error('get product detail failed!', e);
    }
    this.isLoaded = true;
  }
  
  async getLatestProduct(): Promise<void> {
    try {
      this.latestProduct = await lastValueFrom(this.productService.getLatestProduct(10));
    } catch (e) {
      console.error('get latest product failed!', e);
    }
  }
  
  async initBreadCrumb(product: Product): Promise<void> {
    this.breadcrumb.title = product.name;
    const breadcrumbLinks = [];
    
    breadcrumbLinks.push({
      label: await this.translatorService.translate('product_breadcrumb_link_text'),
      link: '/home/shop'
    });

    breadcrumbLinks.push({
      label: product.name,
      link: null
    });
    
    this.breadcrumb.breadcrumbList = breadcrumbLinks;
  }

  // Increament
  increment() {
    this.counter++;
  }

  // Decrement
  decrement() {
    if (this.counter > 1) this.counter--;
  }

  // Add to cart
  async addToCart(product: any) {
    this.isAddingItemToCart = true;
    product.quantity = this.counter || 1;

    if (await this.basketService.addItemToBasket(product,product.quantity)) {
      this.counter = 1;
    }
    this.isAddingItemToCart = false;
  }
  
  // Toggle Mobile Sidebar
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }

  getPrice(product): number{
    return product.itemSupplierList.filter(supplier => supplier.isDefault).map(supplier => supplier.supplierPrice)[0] || 0;
  }
}
