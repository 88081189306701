import {Component, Input, OnInit} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import * as _ from "lodash";
import {DialogService} from "../../../native/dialog/service/dialog.service";
import {SideMenuService} from "../../../component/side-menu/service/side-menu.service";
import {CollectionApiService} from "../../../component/shop/collection/collection/api/collection-api.service";
import {ClientSettingApiService} from "../../../service/client-setting-api-service/client-setting-api.service";
import {TranslatorService} from "../../../service/translator-service/translator.service";
import {ToastrService} from "ngx-toastr";
import {MaterialApiService} from "../../../service/material-api-service/material-api.service";
import {RecipeApiService} from "../../../component/recipe/api/recipe-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {firstValueFrom} from "rxjs";
import {v4 as uuid} from 'uuid';

@Component({
  selector: 'app-add-material-dialog',
  templateUrl: './add-material-dialog.component.html',
  styleUrls: ['./add-material-dialog.component.scss']
})
export class AddMaterialDialogComponent implements OnInit {
  @Input() dialog: Dialog;

  public categoryList: any;
  public categoryItemList: any[];
  public trendingItemList: any[];

  public selectedCategory: any;
  public selectedSubCategory: any;
  public selectedCategoryId: any;

  public searchWord: string;

  public next: any;
  public isNext: boolean;

  public isLoaded: boolean;
  public loadingProduct: boolean;
  public stopLoadMore: boolean;

  public eMenuSelectSub: any;


  public isCreating: boolean;
  public error: any;
  public errorChildItem: any;

  public paramSub: any;

  public itemMeasureSetting: any;
  public isLoadedProductList: boolean;

  public material: any;
  public selectType: string =  'product';

  public materialList: any[];
  public nextMaterial: any;
  public isLoadedMaterialList: boolean;
  public searchMaterialWord: string;
  public excludeIdList: string[];

  constructor(
    public sideMenuService: SideMenuService,
    private collectionApiService: CollectionApiService,
    private clientSettingApiService: ClientSettingApiService,
    private translatorService: TranslatorService,
    private toasterService: ToastrService,
    private materialApiService: MaterialApiService,
    private recipeApiService: RecipeApiService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.sideMenuService.isHomeScreen = true;
    this.categoryItemList = [];

    this.eMenuSelectSub = this.sideMenuService.eMenuSelect.subscribe(() => {
      this.selectedCategory = this.sideMenuService.selectedMenu;
      this.selectedSubCategory = this.sideMenuService.selectedSubMenu;
      this.getCategoryProduct();
      const element = document.getElementById('categoryScrollId');
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    });

    this.error = {};
    this.errorChildItem = [];
    this.materialList = [];
    this.material = {name: '', childList: [], packetInCarton: 1, pieceInPacket: 1};

    this.itemMeasureSetting = {};
    this.searchWord = '';
    this.searchMaterialWord = '';
    this.excludeIdList = [];

    this.onSearch = _.debounce(this.onSearch, 500);
    this.onSearchMaterial = _.debounce(this.onSearchMaterial, 500);
  }

  async ngOnInit() {
    if (!_.isEmpty(this.dialog?.options?.data)) {
      this.material = _.cloneDeep(this.dialog.options.data);
      this.excludeIdList = [this.material._id];
    }
    await this.getProductList();
    await this.getMaterialList();
    await this.getItemMeasureSetting();
    this.isLoaded = true;
  }

  ngOnDestroy() {
    this.eMenuSelectSub.unsubscribe();
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
  }


  async getProductList(): Promise<void> {
    this.isLoadedProductList = false;
    try {
      if (!this.selectedCategoryId) {
        this.selectedCategoryId = 'ALL';
      }
      const response = await firstValueFrom(this.recipeApiService.getProductListForRecipe(24, 0, {search: this.searchWord},
        this.selectedCategoryId));

      if (!this.categoryList) {
        this.categoryList = response.categoryList;
      }

      this.categoryItemList = response.list;

      this.next = response.next;
    } catch (e) {
      console.error('get product failed!', e);
    }
    this.isLoadedProductList = true;
  }

  async getMoreProductList(): Promise<void> {
    if (!this.next) {
      return;
    }
    try {
      if (!this.selectedCategoryId) {
        this.selectedCategoryId = 'ALL';
      }
      const response = await firstValueFrom(this.recipeApiService.getProductListForRecipe(24, this.next, {search: this.searchWord},
        this.selectedCategoryId));

      if (!this.categoryList) {
        this.categoryList = response.categoryList;
      }

      for (const category of response.list) {
        const categoryItem = this.categoryItemList.find(categoryItem => categoryItem._id === category._id);
        if (categoryItem && categoryItem.itemList) {
          categoryItem.itemList = categoryItem.itemList.concat(category.itemList);
        } else {
          this.categoryItemList.push(category);
        }
      }

      this.next = response.next;
    } catch (e) {
      console.error('get more product failed!', e);
    }
  }

  async getMaterialList(): Promise<void> {
    this.isLoadedMaterialList = false;
    try {
      const response = await this.materialApiService.getMaterialList(20, 0, {search: this.searchMaterialWord, excludeIdList: this.excludeIdList}).toPromise();
      this.materialList = response.list;
      this.nextMaterial = response.next;
    } catch (e) {
      console.error('get material list failed!', e);
    }
    this.isLoadedMaterialList = true;
  }
  async getMoreMaterialList(): Promise<void> {
    if(!this.nextMaterial){return ;}
    try {
      const response = await this.materialApiService.getMaterialList(20, this.nextMaterial, {search: this.searchMaterialWord, excludeIdList: this.excludeIdList}).toPromise();
      this.materialList = this.materialList.concat(response.list);
      this.nextMaterial = response.next;
    } catch (e) {
      console.error('get more material failed!', e);
    }
  }


  async getCategoryProduct(): Promise<void> {
    // this.loadingProduct = true;
    //
    // const dom = document.getElementById('v-pills-tabContent');
    // dom.scrollTo({top: 0});

    this.selectedCategoryId = this.selectedSubCategory ? this.selectedSubCategory._id : this.selectedCategory._id;
    await this.getProductList();
    this.loadingProduct = false;
  }

  async getItemMeasureSetting(): Promise<void> {
    try {
      this.itemMeasureSetting = await firstValueFrom(this.recipeApiService.getItemMeasureSetting());
      [this.material.avialableUnitList] =  this.itemMeasureSetting.itemMeasureTypeList.filter(
        itemMeasure => itemMeasure.code === this.material.packing).map(itemMeasure => itemMeasure.unitList);
      if (_.isEmpty(this.material.avialableUnitList)){
        this.material.packing = this.itemMeasureSetting.itemMeasureTypeList[0].code;
        this.material.unitOfMeasurement = this.itemMeasureSetting.itemMeasureTypeList[0].unitList[0].code;
        this.material.avialableUnitList = this.itemMeasureSetting.itemMeasureTypeList[0].unitList;
      }
    } catch (e) {
      console.error("fail item measure setting", e);
    }
  }
  public async onAddMaterial(): Promise<void> {
    if (!this.validateItem()) {return;}
    if (this.checkIsCircular(this.material)) {
      const toasterText = await this.translatorService.translate('Circular dependency detected!');
      this.toasterService.warning(toasterText, null, {positionClass: 'toast-top-left'});
      return;
    }
    this.isCreating = true;
    try {
      await this.materialApiService.createMaterial(this.material).toPromise();
      const toasterText = await this.translatorService.translate('Create material successfully');
      this.toasterService.success(toasterText, null, {positionClass: 'toast-top-left'});
      this.onClose(this.material);
    } catch (e) {
      const toasterText = await this.translatorService.translate('Create material fail');
      this.toasterService.error(toasterText, null, {positionClass: 'toast-top-left'});
      console.error('Create material fail', e);
    }
    this.isCreating = false;
  }

  public async onUpdateMaterial(): Promise<void> {
    if (!this.validateItem()) {return;}
    if (this.checkIsCircular(this.material)) {
      const toasterText = await this.translatorService.translate('Circular dependency detected!');
      this.toasterService.warning(toasterText, null, {positionClass: 'toast-top-left'});
      return;
    }
    this.isCreating = true;
    try {
      await this.materialApiService.updateMaterial(this.material._id, this.material).toPromise();
      const toasterText = await this.translatorService.translate('Update material successfully');
      this.toasterService.success(toasterText, null, {positionClass: 'toast-top-left'});
      this.onClose(this.material);
    } catch (e) {
      const toasterText = await this.translatorService.translate('Update material fail');
      this.toasterService.error(toasterText, null, {positionClass: 'toast-top-left'});
      console.error('Update material fail', e);
    }
    this.isCreating = false;
  }

  public onInputChange(name: string): void {
    this.error[name] = false;
  }

  private validateItem(): boolean {

    this.error.name = _.isEmpty(this.material.name.trim());
    this.error.packing = this.material.packing.trim() === '';
    this.error.packetInCarton = !this.material.packetInCarton;
    this.error.pieceInPacket = !this.material.pieceInPacket;
    this.error.unitOfMeasurement = _.isEmpty(this.material.unitOfMeasurement);
    this.error.childList = _.isEmpty(this.material.childList);
    this.errorChildItem = [];
    let index = 0;
    for (const child of this.material.childList) {
      if (child.name.trim() === '' || _.isEmpty(String(child.qty).trim()) || !child?.qty
        || _.isEmpty(String(child.useUnitOfMeasurement.trim())) || !this.getItemMeasure(child.packing).includes(child.useUnitOfMeasurement)) {
        const e = {
          name: child.name.trim() === '',
          qty: _.isEmpty(String(child.qty).trim()) || !child?.qty,
          measure: _.isEmpty(String(child.useUnitOfMeasurement)) || !this.getItemMeasure(child.packing).includes(child.useUnitOfMeasurement),
        };
        this.errorChildItem.splice(index, 0, e);
      } else {
        this.errorChildItem.splice(index, 0, {name: false, qty: false, measure: false});
      }
      index++;
    }
    for (const e of this.errorChildItem) {
      if (e.name || e.qty || e.measure) {
        return false;
      }
    }
    for (const key in this.error) {
      if (this.error.hasOwnProperty(key)) {
        if (this.error[key]) {
          return false;
        }
      }
    }
    return true;
  }

  onRemoveItem(material: any, index: number): void {
    this.material.childList = this.material.childList.filter(child => child._id !== material._id);
    this.errorChildItem.splice(index, 1);
  }

  onInput(index: number, key: string): void {
    if (key === 'name' && this.errorChildItem[index]) {
      this.errorChildItem[index].name = false;
    }
    if (key === 'qty' && this.errorChildItem[index]) {
      this.errorChildItem[index].qty = false;
    }
    if (key === 'measure' && this.errorChildItem[index]) {
      this.errorChildItem[index].measure = false;
    }
  }

  onCustomProduct(): void {

    // check if there are empty
    for (const child of this.material.childList) {
      if(child.isNeedValidate){
        if (child.type === 'item' && (child.name.trim() === '' || _.isEmpty(String(child.qty).trim()) || !child?.qty
          || _.isEmpty(String(child.useUnitOfMeasurement.trim())))) {
          const errorName = child.name.trim() === '';
          const errorQty = _.isEmpty(String(child.qty).trim()) || !child?.qty;
          const errorMeasure = _.isEmpty(String(child.useUnitOfMeasurement));
          if(errorName || errorQty || errorMeasure){ return;}
        }
      }
    }
    const newCustomItem = {
        _id: uuid(),
        name: '',
        qty: '',
        useUnitOfMeasurement: '',
        isNeedValidate: true,
        packing: 'packing',
        type: 'item'
      };
    this.material.childList.push(newCustomItem);
    this.onInputChange('childList');
  }

  onClickItem(event): void {
    if (event.isAdd) {
      const newAddItem = {
        _id: event.item._id,
        name: event.item.name,
        type: 'item',
        useUnitOfMeasurement: event.item.unitOfMeasurement ?? '',
        unitOfMeasurement: event.item.unitOfMeasurement ?? '',
        packing: event.item.packing ?? '',
        pieceInPacket: event.item.pieceInPacket ?? 0,
        packetInCarton: event.item.packetInCarton ?? 0,
        qty: 1,
        sku: event.item.sku,
        itemSupplierList: event.item.itemSupplierList
      };
      this.material.childList.push(newAddItem);
      this.onInputChange('childList');
    } else {
      this.material.childList = this.material.childList.filter(child => child._id !== event.item._id);
    }

  }

  isItemSelected(item): boolean {
    return this.material.childList?.some(child => child._id === item._id);
  }

  getItemMeasure(measureType: string): string[] {
    let matchedObject = this.itemMeasureSetting.itemMeasureTypeList.find(m => m.code === measureType);
    if (matchedObject) {
      return matchedObject.unitList.map(sub => sub.code);
    }
    return this.itemMeasureSetting.itemMeasureTypeList.map(measure => measure.unitList.map(sub => sub.code)).flat();
  }

  onSearch(): void {
    this.getProductList().then();
  }

  onSearchMaterial(): void{
    this.getMaterialList().then();
  }


  onClose(data?: any): void {
    this.dialog.close(data, {disableClose: false});
  }

  onCancel(): void {
    this.onClose();
  }


  isHaveProduct(): boolean {
    return !_.isEmpty(this.material.childList.filter(child => !child?.isNeedValidate));
  }

  isHaveCustomProduct(): boolean {
    return !_.isEmpty(this.material.childList.filter(child => child?.isNeedValidate));
  }

  isMaterialSelected(material): boolean{
    return this.material.childList?.some(child => child._id === material._id);
  }

  onClickMaterial(material): void{
    if(!['accepted'].includes(material.status)){ return;}
    if(!this.isMaterialSelected(material)){
      material.type = 'material';
      material.qty = 1;
      material.useUnitOfMeasurement = this.getItemMeasure(material.packing)[0];
      this.material.childList.push(material);
      this.onInputChange('childList');
    } else{
      this.material.childList = this.material.childList.filter(child => child._id !== material._id);
    }
  }

  checkIsCircular(node, visited = new Set()): boolean {
    if (visited.has(node._id)) {
      return true; // Circular dependency found
    }

    visited.add(node._id); // Mark the current node as visited

    if (node.childList) {
      for (let child of node.childList) {
        if (this.checkIsCircular(child, visited)) {
          return true; // Circular dependency found in child
        }
      }
    }

    visited.delete(node._id); // Unmark the current node before returning (for other branches)
    return false;
  }
  onChangePacking(): void{
    const [unitList] = this.itemMeasureSetting.itemMeasureTypeList.filter(
      itemMeasure => itemMeasure.code === this.material.packing).map(itemMeasure => itemMeasure.unitList);
    this.material.avialableUnitList = unitList;
    this.material.unitOfMeasurement = this.material.avialableUnitList[0].code;
    this.onInputChange('packing');
  }
}
