<div class="w90vw h90vh fxColumn px-4 py-2 gap24">
  <div class="fxRow fxRowSpaceBetween">
    <h3 class="w20p noSelect">{{(dialog.options?.data ? 'Update Semi-Product' : 'Add Semi-Product')| translate }}</h3>
    <div class="w80p fxRow" style="gap: 32px">
      <div class="fxRow fullWidth w250">
        <div class="w50p fxRow fxRowCenter fontBold px12 pt12 border cursorPointer noSelect" (click)="selectType = 'product'; searchMaterialWord = ''" [class.backgroundPrimary]="selectType === 'product'" [class.colorWhite]="selectType === 'product'">{{'Product'}}</div>
        <div class="w50p fxRow fxRowCenter fontBold px12 pt12 border cursorPointer noSelect" (click)="selectType = 'material'; searchWord = ''" [class.backgroundPrimary]="selectType === 'material'" [class.colorWhite]="selectType === 'material'">{{'Semi-Product'}}</div>
      </div>
      <form *ngIf="selectType === 'product'" class="form_search rounded-1 w450">
        <input #searchInput  id="query search-autocomplete"
               name="search-product"
               [placeholder]="'header_search_hint'| translate" class="nav-search nav-search-field"
               [(ngModel)]="searchWord"
               (input)="onSearch()">
        <button type="submit" class="btn-search">
          <i class="ti-search"></i>
        </button>
      </form>
      <form *ngIf="selectType === 'material'" class="form_search rounded-1 w450">
        <input id="query search-autocomplete"
               name="search-product"
               [placeholder]="'Search any Semi-Product...'| translate" class="nav-search nav-search-field"
               [(ngModel)]="searchMaterialWord"
               (input)="onSearchMaterial()">
        <button type="submit" class="btn-search">
          <i class="ti-search"></i>
        </button>
      </form>
    </div>
  </div>
  <!--select item-->
  <div class="fadeIn fxColumn" *ngIf="isLoaded">
    <!-- product listing section start -->
    <div class="fxRow gap12" style="height: calc(100vh - 212px);">
      <div class="fxColumn w70p fullHeight">
        <div *ngIf="selectType === 'product'" class="fxRow fullHeight fullWidth">
          <div class="w30p fullHeight">
            <app-side-menu [items]="categoryList"></app-side-menu>
          </div>
          <div *ngIf="isLoadedProductList"
               infiniteScroll
               [infiniteScrollUpDistance]="2"
               [infiniteScrollThrottle]="50"
               [scrollWindow]="false"
               (scrolled)="getMoreProductList()"
               class="w70p h-no-header tab-content scrollY" style="border-right: 1px solid #ddd;"
          >
            <div class="tab-pane fade show active px-3" id="grocery">
              <div id="categoryScrollId"></div>
              <div *ngIf="categoryItemList.length > 0">
                <ng-container *ngFor="let categoryItem of categoryItemList; let i = index;">
                  <div *ngIf="!loadingProduct && categoryItem.itemList && categoryItem.itemList[0]">
                    <div class="title8 mt24">
                      <div class="fxRow fxRowSpaceBetween fxColumnCenter">
                        <div class="fxColumn">
                          <h2>{{ categoryItem?.label || '-' }}</h2>
                          <p *ngIf="categoryItem.description">{{ categoryItem.description }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="row g-sm-4 g-3">
                      <app-product-select
                        *ngFor="let product of categoryItem.itemList"
                        [product]="product"
                        [loader]="loadingProduct"
                        (eItemClick)="onClickItem($event)"
                        [isSelected]="isItemSelected(product)"
                        class = "w30p"
                      ></app-product-select>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="categoryItemList.length <= 0" class="h450 fullWidth fxRow fxRowCenter fxColumnCenter">
                <div>
                  <div class="fxRow fxRowCenter textGray font18 noSelect" style="padding-bottom: 64px">{{'No Product'}}</div>
                  <button  class="btn btn-solid" (click)="onCustomProduct()">
                    {{ 'Add Custom ingredient' | translate }}
                  </button>
                </div>
              </div>

            </div>
          </div>
          <div *ngIf="!isLoadedProductList" class="w70p h-no-header tab-content fxRow fxColumnCenter fxRowCenter">
            <img class="loading-icon" src="./assets/image/img/product/loader.gif" >
          </div>
        </div>
        <div *ngIf="selectType === 'material'" class="fullHeight fullWidth mt12">
          <div *ngIf="isLoadedMaterialList"
               infiniteScroll
               [infiniteScrollUpDistance]="2"
               [infiniteScrollThrottle]="50"
               [scrollWindow]="false"
               (scrolled)="getMoreMaterialList()"
               class="h-no-header tab-content scrollY" style="border-right: 1px solid #ddd;"
          >
            <div class="tab-pane fade show active px-3" id="grocery">
              <div id="categoryScrollId"></div>
              <div>
                <ng-container>
                  <div class="row g-sm-4 g-3">
                    <div *ngFor="let material of materialList; let i = index;" class="w50p">
                      <div class="product-box product-style-5 fullHeight gap12 noSelect" [class.cursorPointer]="['accepted'].includes(material.status)" (click)="onClickMaterial(material)">
                        <div class="fxRow fxRowSpaceBetween">
                          <div class="pt-2 font20 fontBold">{{ material?.name | titlecase }}</div>
                          <app-check-box *ngIf="['accepted'].includes(material.status)" [disable]="true" [checked]="isMaterialSelected(material)"></app-check-box>
                          <div *ngIf="['pending'].includes(material.status)" class="status-container status-pending">{{'PENDING' | translate}}</div>
                          <div *ngIf="['declined'].includes(material.status)" class="status-container status-declined">{{'DECLINED' | translate}}</div>
                        </div>
                        <div class="font16 fontBold pt6 pb12">${{material.totalPrice | number: '1.2-2'}}</div>
                        <div *ngFor="let child of material.childList" class="fxRow gap6 fullWidth">
                          <div class="w5p fxRow fxRowEnd fontBold">-</div>
                          <div class="w60p">{{child.name}}</div>
                          <div class="w20p">{{child.qty}} {{child.useUnitOfMeasurement}}</div>
                          <div class="w15p">${{child.totalPrice | number: '1.2-2'}}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                </ng-container>
              </div>
              <div *ngIf="materialList.length <= 0" class="h450 fullWidth fxRow fxRowCenter fxColumnCenter">
                <div>
                  <div class="fxRow fxRowCenter textGray font18 noSelect" style="padding-bottom: 64px">{{'No Semi-Product'}}</div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isLoadedMaterialList" class="h-no-header tab-content fxRow fxColumnCenter fxRowCenter">
            <img class="loading-icon" src="./assets/image/img/product/loader.gif" >
          </div>
        </div>
      </div>
      <div class="w30p fullHeight fxColumn">
        <div class="mb-3">
          <label for="form-input-name" class="form-label">{{ 'Semi-Product name' | translate }}</label>
          <input id="form-input-name"
                 [(ngModel)]="material.name"
                 [class.is-invalid]="error.name"
                 (input)="onInputChange('name')"
                 class="form-control"
                 [placeholder]="'Semi-Product name' | translate"
          />
        </div>
        <div class="mb-3">
          <label for="form-input-name" class="form-label">{{ 'Packing Unit Type' | translate }}</label>
          <select [(ngModel)]="material.packing" [class.is-invalid]="error.packing"
                  (change)="onChangePacking()" class="form-control fullWidth" placeholder="Category" style="appearance: auto !important;">
            <option *ngFor="let measure of itemMeasureSetting.itemMeasureTypeList" [value]="measure.code">{{measure.code}}</option>
          </select>
        </div>
        <div class="fxRow gap12">
          <div class="mb-3 w30p">
            <label for="form-input-name" class="form-label">{{ 'Pieces' | translate }}</label>
            <input [(ngModel)]="material.packetInCarton"
                   class="form-control fullWidth" type="number" min="0" pattern="^[0-9]"
                   onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" [class.is-invalid]="error.packetInCarton"
                   (input)="onInputChange('packetInCarton')"/>
          </div>
          <div class="fontSemiBold textGray" style="padding-top: 42px">{{'X'}}</div>
          <div class="mb-3 w30p">
            <label for="form-input-name" class="form-label">{{ 'Quantity' | translate }}</label>
            <input [(ngModel)]="material.pieceInPacket"
                   class="form-control fullWidth" type="number" min="0" pattern="^[0-9]"
                   onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" [class.is-invalid]="error.pieceInPacket"
                   (input)="onInputChange('pieceInPacket')"/>
          </div>
          <div class="mb-3 w30p">
            <label for="form-input-name" class="form-label">{{ 'Unit' | translate }}</label>
            <select [(ngModel)]="material.unitOfMeasurement" [class.is-invalid]="error.unitOfMeasurement"
                    (change)="onInputChange('unitOfMeasurement')" class="form-control fullWidth" placeholder="Category" style="appearance: auto !important;">
              <option *ngFor="let unit of material.avialableUnitList" [value]="unit.code">{{unit.code}}</option>
            </select>
          </div>
        </div>
        <div class="mb-4 fxColumn backgroundOver p12 borderRadius" style="background-color: #f8f8f8; height: calc(100% - 350px)">
          <div class="fxRow fxColumnCenter">
            <label for="form-input-subject" class="form-label">{{ 'Ingredients' | translate }}</label>
            <label *ngIf="error.childList" for="form-input-subject" class="form-label colorDanger ml12 font12">{{ 'Please add at least one ingredient' | translate }}</label>
          </div>
          <div *ngIf="!isHaveProduct()" class="fullHeight fxRow fxRowCenter fxColumnCenter">{{'No Data'}}</div>
          <div *ngIf="isHaveProduct()" class="fullHeight scrollY borderRadius p12" [class.borderDanger]="error.childList" [class.h222]="isHaveCustomProduct()">
            <div *ngFor="let child of material.childList; let index = index;">
              <div *ngIf="!child?.isNeedValidate" class="fxRow fxColumnCenter fxRowSpaceBetween py6 borderRadius mb12" style="gap: 12px">
                <div  class="fontSemiBold w40p">{{child.name}}</div>
                <input [(ngModel)]="child.qty" type="number" min="1" pattern="^[0-9]" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                       [class.is-invalid]="errorChildItem[index] && errorChildItem[index].qty" placeholder="Amount" (input)="onInput(index ,'qty')"
                       class="form-control w25p"
                />
                <select [(ngModel)]="child.useUnitOfMeasurement" [class.is-invalid]="errorChildItem[index] && errorChildItem[index].measure"
                        (change)="onInput(index ,'measure')" class="form-control w30p" style="appearance: auto !important;">
                  <option *ngFor="let uom of getItemMeasure(child.packing)" [value]="uom">{{uom}}</option>
                </select>
                <div class="w10p fxRow fxRowCenter fxColumnCenter">
                  <div class="cursorPointer" (click)="onRemoveItem(child, index)">
                    <svg-icon src="./assets/image/icon/shop/cart/delete-filled-icon.svg"
                              class="size20"></svg-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4 fxColumn borderRadius p12" style="background-color: #f8f8f8; height: calc(100% - 400px)">
          <div class="fxRow fxColumnCenter">
            <label for="form-input-subject" class="form-label">{{ 'Custom Ingredients' | translate }}</label>
          </div>
          <div *ngIf="!isHaveCustomProduct()" class="fullHeight fxRow fxRowCenter fxColumnCenter">{{'No Data'}}</div>
          <div *ngIf="isHaveCustomProduct()" class="fullHeight scrollY borderRadius">
            <div *ngFor="let child of material.childList; let index = index;">
              <div *ngIf="child?.isNeedValidate" class="fxRow fxColumnCenter fxRowSpaceBetween py6 borderRadius mb12" style="gap: 12px;">
                <input [(ngModel)]="child.name"
                       [class.is-invalid]="errorChildItem[index] && errorChildItem[index].name" placeholder="Name"
                       class="form-control w35p" (input)="onInput(index ,'name')"
                />
                <input [(ngModel)]="child.qty" type="number" min="1" pattern="^[0-9]" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                       [class.is-invalid]="errorChildItem[index] && errorChildItem[index].qty" placeholder="Amount" (input)="onInput(index ,'qty')"
                       class="form-control w25p"
                />
                <select [(ngModel)]="child.useUnitOfMeasurement" [class.is-invalid]="errorChildItem[index] && errorChildItem[index].measure"
                        (change)="onInput(index ,'measure')" class="form-control w30p" placeholder="Measure" style="appearance: auto !important;">
                  <option *ngFor="let uom of getItemMeasure(child.useUnitOfMeasurement)" [value]="uom">{{uom}}</option>
                </select>
                <div class="w10p fxRow fxRowCenter fxColumnCenter">
                  <div class="cursorPointer" (click)="onRemoveItem(material, index)">
                    <svg-icon src="./assets/image/icon/shop/cart/delete-filled-icon.svg"
                              class="size20"></svg-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fxRow fxRowEnd gap12">
      <button class="btn btn-solid" (click)="onCancel()">
        {{ 'Cancel' | translate }}
      </button>
      <button *ngIf="!dialog.options?.data" class="btn btn-solid" (click)="onAddMaterial()" [disabled]="isCreating || material?.childList?.length <= 0">
        {{ 'Save' | translate }}
      </button>
      <button *ngIf="dialog.options?.data" class="btn btn-solid" (click)="onUpdateMaterial()" [disabled]="isCreating || material?.childList?.length <= 0">
        {{ 'Update' | translate }}
      </button>
      <app-loader-overlay *ngIf="isCreating"></app-loader-overlay>
    </div>
  </div>
  <!--select material-->

  <div *ngIf="!isLoaded" class="fullHeight">
    <!-- product listing section start -->
    <div class="fullHeight">
      <div class="row">
        <div class="col-3">
          <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'width': '320px', 'border-radius': '0', height: '80vh' }"></ngx-skeleton-loader>
        </div>

        <div class="col-6 px-3">
          <div class="row g-sm-4 g-3">
            <app-skeleton-product-box
              *ngFor="let product of [].constructor(4)"
              class="col-xxl-3"
            ></app-skeleton-product-box>
          </div>
        </div>
        <div class="col-2">
          <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'width': '320px', 'border-radius': '0', height: '80vh', 'padding-lef': '90px' }"></ngx-skeleton-loader>
        </div>

      </div>
    </div>
    <!-- product listing section end -->
  </div>
</div>
