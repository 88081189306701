<div *ngIf="recipe" class="h60vh w600 fxColumn px-2 py-2">
    <div class="mb-4 fxRow justify-content-between align-items-center">
        <h3 class="col-11">
            Recipe Detail
        </h3>
        <i class="ti-close font20 col-1 cursorPointer" (click)="dialog.close()"></i>
    </div>
    <div class="fullHeight fadeIn fxColumn scrollY">
        <div class="fxColumnStart">
            <div class="fxRow">
                <label class="form-label mr12">{{ 'Name:' | translate }}</label>
                <div class="fontSemiBold pl12">{{recipe.name ? recipe.name : '-'}}</div>
            </div>
          <div class="fxRow">
            <label class="form-label mr12">{{ 'Business Line:' | translate }}</label>
            <div class="fontSemiBold pl12">{{recipe.outlet?.name || '-'}}</div>
          </div>
          <div class="fxRow">
            <label class="form-label mr12">{{ 'Category:' | translate }}</label>
            <div class="fontSemiBold pl12">{{recipe.categoryList[0]?.name || '-'}}</div>
          </div>
          <div class="fxRow">
            <label class="form-label mr12">{{ 'Cost:' | translate }}</label>
            <div class="fontSemiBold pl12">${{recipe.totalPrice | number:'1.2-2'}}</div>
          </div>
          <div class="fxRow fxColumnCenter">
              <label  class="form-label mr12">{{ 'Status: ' | translate }}</label>
              <div class="fxRow fxColumnCenter fxRowCenter">
                  <div class="status-container"
                       [class.status-draft] ="recipe.status === 'draft'"
                       [class.status-pending] ="recipe.status === 'pending'"
                       [class.status-accepted] ="recipe.status === 'accepted'"
                       [class.status-declined] ="recipe.status === 'declined'"
                  >{{recipe.status | uppercase}}</div>
              </div>
          </div>
        </div>

        <div class="mb-4 fxColumn">
            <div class="fxRow fxColumnCenter">
                <label for="form-input-subject" class="font16 fontSemiBold py12">{{ 'Ingredients' | translate }}</label>
            </div>
            <div *ngIf="recipe?.childList?.length <= 0" class="h150 fxRow fxRowCenter fxColumnCenter">{{'No Ingredient'}}</div>
<!--            <div *ngIf="recipe?.childList?.length > 0" class="fxRow fxColumnCenter fxRowSpaceBetween mb12">-->
<!--                <div class="fontBold w60p pl12 font12">{{'Name'}}</div>-->
<!--                <div class="fontBold w20p fxRow fxRowCenter font12">{{'Qty'}}</div>-->
<!--                <div class="fontBold w20p fxRow fxRowCenter font12">{{'Measure'}}</div>-->
<!--            </div>-->
          <div *ngFor="let child of recipe.childList; let index = index;" class="mb12 py">
            <div class="fxRow fxColumnCenter fxRowSpaceBetween borderRadius gap12">
              <div></div>
              <div class="fontSemiBold w60p">{{child.name}}</div>
              <div class="w10p fxRow fxRowEnd font12">{{child.qty}} {{child.useUnitOfMeasurement}}</div>
              <div class="w10p fxRow fxRowCenter font12">${{child.totalPrice | number: '1.2-2'}}</div>
              <div class="w20p fxRow fxRowCenter font12">
                <div *ngIf="recipe.status === 'accepted' && userService.user?.role?.type !== 'hq_operator'" class="addToCartButton cursorPointer button backgroundPrimary colorWhite fontSemiBold px12 py6 borderPrimary"  (click)="onAddToCart(child)">{{'Add to Cart' | translate}}</div>
              </div>
            </div>
<!--            <div *ngFor="let nestedChild of child.childList" class="fxRow mt6 py6 colorGray gap12">-->
<!--              <div class="w5p fxRow fxRowEnd fontBold font12">-</div>-->
<!--              <div class="w55p font12">{{nestedChild.name}}</div>-->
<!--              <div class="w20p fxRow fxRowEnd font12">{{nestedChild.qty}} {{nestedChild.unitOfMeasurement}}</div>-->
<!--              <div class="w20p fxRow fxRowCenter font12">${{nestedChild.price | number: '1.2-2'}}</div>-->
<!--            </div>-->
          </div>
        </div>
    </div>
</div>
