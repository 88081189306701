<!-- side-bar single product slider start -->
<div class="theme-card">
  <h5 class="title-border">{{ title }}</h5>
  <owl-carousel-o [options]="newProductSliderConfig">
    <ng-container *ngFor="let product of products | slice:0:4; let i = index">
      <ng-template carouselSlide>
        <div>
          <div class="d-flex align-items-center" *ngFor="let product of products | slice:i * 2:(i * 2) + 2">
            <a class="ratio ratio-1x1 offer-slider" [routerLink]="['/home/shop/product/', product._id]"
               (click)="onGotoDetail()">
              <img class="img-fluid rounded-2"
                   [defaultImage]="'assets/image/img/product/placeholder.jpg'"
                   [lazyLoad]="product.thumbnail" alt="">
            </a>
            <div class="media-body align-self-start">
              <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
              <a [routerLink]="['/home/shop/product/', product._id]" (click)="onGotoDetail()">
                <h6 class="m-0">{{ product.name | titlecase }}</h6>
              </a>
              <div *ngIf="product.supplier && userService.user?.organization?.isAllowViewSupplier"
                   class="fxRow fxColumnCenter my6">
                <img  *ngIf="product.supplier?.logoUrl"
                      [src]="product.supplier?.logoUrl"
                      class="h30 w30 img-fluid rounded-circle border">
                <h4 class="colorPrimary" [class.ml12]="product.supplier?.logoUrl">{{ product.supplier?.name }}</h4>
              </div>
              <h4 *ngIf="product.pieceInPacket && product.packetInCarton && product.unitOfMeasurement" class="noSelect pb-2">{{product.pieceInPacket}}x{{product.packetInCarton}}{{product.unitOfMeasurement}}</h4>
              <h4 *ngIf="userService.isAllowViewPrice">{{ getPrice(product) | currency: productService.Currency.currency:'symbol' }}</h4>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->
